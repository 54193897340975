/**
 * File: prodreg.getall.component
 * Created by Ricky Leung on 2018-08-12
 */
import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {ProdRegExcelService} from "../prodreg.services/prodreg.excel.service";
import * as _ from "lodash";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {ProdRegLogoComponent} from "../prodreg.logo/prodreg.logo.component";
import {SnackbarComponent} from "../../shared/snackbar/snackbar.component";
import {DataAccessService} from "../../shared/dataAccess.service";
import {ProdRegGetAllDataSource} from "./prodreg.getall.datasource";
import {Observable} from "rxjs";
import {ProdRegUpdateService} from "../prodreg.services/prodreg.update.service";
import {Router} from "@angular/router";

@Component({
  selector: "onelab-prodreg-getall",
  templateUrl: "./prodreg.getall.html",
  styleUrls: ["../../shared/share.style.css", "./prodreg.getall.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class ProdRegGetAllComponent implements OnInit {
  columns = [];
  displayedColumns = [];
  highlightRow = [];
  dataSource: ProdRegGetAllDataSource;

  constructor(public router: Router, private prodRegUpdateService: ProdRegUpdateService, private cdRef: ChangeDetectorRef,
              private dialog: MatDialog, private snackBar: SnackbarComponent,
              private dataAccessService: DataAccessService) {}

  ngOnInit() {
    this.dataSource = new ProdRegGetAllDataSource(this.dataAccessService);
    this.dataSource.prodRegGetAll();

    this.buildColumnDefinitions().subscribe(colDef => {
      this.columns = colDef;
      this.displayedColumns = colDef.map(col => col.columnDef);
    });

  }

  /* Ricky: we're dynamically building the preview table based off of the parsed Product Registration Excel file
  *   - we take 1 sample from the product registration items data object and get the object keys as the column headers
  *   - for each object key, we create the required column data object and add it to our temporary column data array
  *   - column data object consists of the following:
  *     - columnDef: a unique identifier for the column
  *     - header: text displayed as the column header
  *     - cell: an anonymous function used to get the data I want to display based off of the column's key
  *   - once the column data is created its assigned to our public class property
  * */
  buildColumnDefinitions() {
    const rejectKeys = ["company_id", "createdAt", "updatedAt", "productLogo"];

    return Observable.create(observer => {
      this.dataAccessService.companyProductGetAll()
        .subscribe(response => {
          const productItems = response.message;  // product data items returned
          const columnData = [];
          const colKeys = _.keys(_.sample(productItems));

          // filter out the keys we don't want, push in the keys we want to add
          const colKeysFiltered = _.reject(colKeys, key => _.indexOf(rejectKeys, key) !== -1);
          colKeysFiltered.push("Image");

          // build the column definitions for the table
          _.forEach(colKeysFiltered, key => {
            columnData.push({ columnDef: key, header: key, cell: (item: any) => `${item[key]}`});
          });

          observer.next(columnData);
          observer.complete();
        });
    });


  }

  UpdateProductInfo(productData) {
    console.log(productData);
    this.prodRegUpdateService.updateProductData(productData);

    this.router.navigateByUrl(`/product/register/update?id=${productData.id}`);
  }

}
