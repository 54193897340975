/**
 * File: onelab-user-settings.component
 * Created by Ricky Leung on 2020-10-03
 */
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import { LocaleService, TranslationService, Language } from "angular-l10n";
import {SnackbarComponent} from "../snackbar/snackbar.component";
import {DataAccessService} from "../dataAccess.service";

@Component({
  selector: "onelab-user-settings",
  templateUrl: "./onelab-user-settings.html",
  styleUrls: ["../share.style.css", "./onelab-user-settings.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class OnelabUserSettingsComponent implements OnInit {
  @Language() lang: string;
  private localeObserve = this.locale.languageCodeChanged.asObservable();

  routes = [
    { label: "Update Password", labelKey: "PASSWORD_UPDATE", url: "/settings/password" },
    { label: "Logout", labelKey: "SIDENAV_LOGOUT", url: "/logout" }
  ];

  email: string;
  firstName: string;
  lastName: string;

  constructor(public locale: LocaleService,
              public translation: TranslationService,
              public router: Router,
              private snackBar: SnackbarComponent,
              private dataAccessService: DataAccessService) {}

  ngOnInit() {
    this.dataAccessService.getProfile()
      .subscribe(response => {
        if (response.code === 200) {
          this.email = response.message.email;
          this.firstName = response.message.firstName;
          this.lastName = response.message.lastName;
        }
      });
  }

  RouteUrl(route) {
    this.router.navigateByUrl(route.url);
  }


}
