/**
 * File: prodreg.update.component
 * Created by Ricky Leung on 2018-08-13
 */
import {AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit} from "@angular/core";
import {DataAccessService} from "../../shared/dataAccess.service";
import {SnackbarComponent} from "../../shared/snackbar/snackbar.component";
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {ProdRegUpdateService} from "../prodreg.services/prodreg.update.service";
import * as _ from "lodash";
import {NgForm} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {IImage} from "../../models/iimage";
import {FileSystemFileEntry, UploadEvent, UploadFile} from "ngx-file-drop";
import { catchError, map, tap } from "rxjs/operators";
import {Language, LocaleService, TranslationService} from "angular-l10n";

@Component({
  selector: "onelab-prodreg-update",
  templateUrl: "./prodreg.update.html",
  styleUrls: ["../../shared/share.style.css", "./prodreg.update.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class ProdRegUpdateComponent implements OnInit, OnChanges, AfterViewInit {
  @Language() lang: string;
  private localeObserve = this.locale.languageCodeChanged.asObservable();

  private imageDataSource = new BehaviorSubject<IImage>(null);
  private currentImageData = this.imageDataSource.asObservable();

  _productId: string;
  _productData: any = {};
  _dataKeys: any = [];

  // Brand: string;
  // Category: string;
  // Color: string;
  // Fabric: string;
  // Group: string;
  // ItemDescription: string;
  // MaterialContent: string;
  // Price: any;
  // ProductName: string;
  // Size: string;
  // Style: string;
  // UOM: string;
  // UPC: string;
  // company_id: string;
  // createdAt: string;
  // id: string;
  productLogo: IImage = { filename: "", filetype: "", value: "" };
  // updatedAt: string;

  constructor(public router: Router,
              public route: ActivatedRoute,
              private dataAccessService: DataAccessService,
              private cdRef: ChangeDetectorRef,
              private prodRegUpdateService: ProdRegUpdateService,
              private snackBar: SnackbarComponent,
              public translation: TranslationService,
              public locale: LocaleService) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      if (_.has(params, "id")) {
        // const productId = params.get("id");
        const productId = _.get(params, "id");
        this._productId = productId;

        this.dataAccessService.companyProductGetOne(productId)
          .subscribe(response => {
            if (response.code === 200) {
              this._productData = response.message;
              this._dataKeys = _.keys(this._productData);
              this.productLogo = this._productData.productLogo;
            }
          });
      }
    });
  }

  ngOnChanges() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.currentImageData.subscribe(response => {
      if (!_.isNil(response)) {
        this.productLogo = response;
        this.cdRef.detectChanges();
      }
    });

    this.localeObserve.subscribe(response => {
      if (!_.isNil(response)) {
        this.translation.loadTranslation().then(() => {});
      }
    });
  }

  OnFileDropEvent(event: UploadEvent) {
    const reader: FileReader = new FileReader();
    const img = new Image();
    const oneFile: UploadFile = event.files[0];

    if (oneFile.fileEntry.isFile) {
      const fileEntry = oneFile.fileEntry as FileSystemFileEntry;

      fileEntry.file((file: File) => {

        // FileReader -- handler for "load" event
        reader.onload = (readerEv: Event) => {
          const readerResult = _.toString(reader.result);

          // Image -- handler for "load" event
          img.onload = (imageEv: Event) => {
            const imageDataObject: IImage = {
              filename: file.name, filetype: file.type, value: readerResult
            };

            this.imageDataSource.next(imageDataObject);
            this.imageDataSource.complete();
          };

          img.src = readerResult;
        };

        reader.readAsDataURL(file);
      });
    }
  }

  UpdateRegisteredProduct(brand, category, color, fabric, group, itemDesc, matContent, price, prodName, size, style, uom, upc, id) {
    // console.log("--->>> inside UpdateRegisteredProduct");
    const productId = this._productData.id;
    const productData = {
      Brand: brand, Category: category, Color: color, Fabric: fabric, Group: group, ItemDescription: itemDesc, MaterialContent: matContent,
      Price: _.toNumber(price), ProductName: prodName, Size: size, Style: style, UOM: uom, productLogo: this.productLogo,
      company_id: this._productData.company_id, UPC: this._productData.UPC
    };
    // console.log(productData);
    // console.log(`PUT /company/product/${id}`);

    this.dataAccessService.companyProductUpdate(productId, productData)
      .subscribe(response => {
        if (response.code === 200) {
          this.snackBar.success(`Successfully update product ID: ${productId}`);
        }
      }, error => {
        this.snackBar.error(error.message);
      });

  }

  ReturnToProductList() {
    this.router.navigateByUrl("/product/register/getall")
      .then(
        resolve => resolve,
        reject => reject
      )
      .catch(
        reject => reject
      );

    // this.router.navigateByUrl("/product/register/getall")
    //   .then(
    //     resolve => { return resolve; },
    //     reject => { return reject; }
    //   )
    //   .catch(
    //     reject => { return reject; }
    //   );
  }

  // onSubmit(productData: NgForm) {
  //   event.preventDefault();
  //
  //   console.log("-----> prodreg.update.component: onSubmit");
  //   // console.log(productData.form);
  //   console.log(productData.value);
  // }

}
