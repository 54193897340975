/**
 * File: l10n-config
 * Created by Ricky Leung on 2019-05-04
 */
import {L10nConfig, LogLevel, ProviderType, StorageStrategy} from "angular-l10n";

export const L10N_CONFIG: L10nConfig = {
  logger: {
    level: LogLevel.Warn
  },
  locale: {
    languages: [
      { code: "en", dir: "ltr" },
      { code: "fr", dir: "ltr" }
    ],
    language: "en",
    storage: StorageStrategy.Cookie
  },
  translation: {
    providers: [
      // { type: ProviderType.Static, prefix: "./assets/locale-" }
      { type: ProviderType.Static, prefix: "./assets/locale/locale-" }
    ],
    caching: true,
    composedKeySeparator: ".",
    missingValue: "No key"
  }
};
