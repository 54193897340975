/**
 * File: qr-code-reader.service
 * Created by Ricky Leung on 2019-12-23
 */
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/index";

declare const qrcode: any;

@Injectable()
export class QrCodeReaderService {

  decode(file: any): Observable<string> {
    return new Observable(observer => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (event: any) => {
        const data = event.target.result;
        qrcode.callback = (res) => {
          observer.next(res);
          observer.complete();
        };
        qrcode.decode(data);
      };

    });
  }
}
