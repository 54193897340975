/**
 * File: dpassword
 * Created by Ricky Leung on 2017-10-15
 */
export class DPassword {
  data: string;
  constructor(data: string) {
    this.data = data;
  }
}
