/**
 * File: prodreg.excel.service
 * Created by Ricky Leung on 2018-07-28
 */
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import * as _ from "lodash";
import {IImage} from "../../models/iimage";
import {PRODREG_EXCEL_IMAGE} from "../../shared.data/prodreg.excel.image";

@Injectable()
export class ProdRegExcelService {
  private _prodRegItems: any = [];
  private dataSourceProdRegItems = new BehaviorSubject<any>(this._prodRegItems);
  currentProdRegItems = this.dataSourceProdRegItems.asObservable();

  constructor() {}

  // -- reset methods
  resetProdRegItems() {
    this.dataSourceProdRegItems.next(this._prodRegItems);
  }

  // -- update methods
  updateProdRegItems(prodRegItems: any) {
    // add "Image" to each item in product registration array
    _.each(prodRegItems, (item) => {

      const productLogo: IImage = {
        filename: "", filetype: "", value: ""
      };
      item["productLogo"] = productLogo;

      // todo: item["Image"] should be blank string
      item["Image"] = PRODREG_EXCEL_IMAGE.value;
    });

    this.dataSourceProdRegItems.next(prodRegItems);
  }

}
