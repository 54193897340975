/**
 * File: loader.service
 * Created by Ricky Leung on 2019-03-18
 */
import {Injectable} from "@angular/core";
import {Subject} from "rxjs/index";
import {LoaderState} from "./loader.state";

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {}

  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }

}
