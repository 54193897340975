// Angular core and third party modules
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgModule} from "@angular/core";
import {HttpClient, HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FileDropModule} from "ngx-file-drop";
import {OnelabMaterialModule} from "./shared/onelab-material-module";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { L10nConfig, L10nLoader, TranslationModule, StorageStrategy, ProviderType, LogLevel } from "angular-l10n";
import {L10N_CONFIG} from "./shared.data/l10n-config";
import {ChartsModule} from "ng2-charts";

// Onelab customized UI components
import {OnelabInputPasswordComponent} from "./shared/onelab-input-password/onelab-input-password.component";
import {OnelabNavListGroupComponent} from "./shared/onelab-nav-list-group/onelab-nav-list-group.component";
import {OnelabNavListItemComponent} from "./shared/onelab-nav-list-item/onelab-nav-list-item.component";
import {OnelabTitleComponent} from "./shared/onelab-title/onelab-title.component";
import {OnelabInputGeneralComponent} from "./shared/onelab-input-general/onelab-input-general.component";
import {OnelabRenderSideNavComponent} from "./shared/onelab-render-sidenav/onelab-render-sidenav.component";

// App routing and route guards
import {routing} from "./app.routes";
import {PublicComponent} from "./layouts/public.component";
import {SecureComponent} from "./layouts/secure.component";
import {CanActivateViaAuthGuard} from "./routeguards/auth.canActivateGuard";
import {CanActivateViaPermissionGuard} from "./routeguards/permission.canActivateGuard";
import {CanActivateViaRoleGuard} from "./routeguards/role.canActivateGuard";

// Shared services
import {DataAccessService} from "./shared/dataAccess.service";
import {RequestInterceptService} from "./shared/request-intercept.service";
import {LoaderInterceptService} from "./shared/loader-intercept.service";

// Page/feature components
import {AppComponent} from "./app.component";
import {HomeComponent} from "./home/home.component";
import {LogInComponent} from "./login/login.component";
import {LogOutComponent} from "./logout/logout.component";
import {RegisterComponent} from "./register/register.component";
import {UserActivationComponent} from "./register/useractivation.component";
import {TwoFactorAuthComponent} from "./settings/twofactorauth/twofactorauth.component";
import {ResetPasswordRequestComponent} from "./resetpasswordrequest/resetpasswordrequest.component";
import {ResetPasswordUpdateComponent} from "./resetpasswordupdate/resetpasswordupdate.component";
import {LoaderComponent} from "./shared/loader/loader.component";
import {LoaderService} from "./shared/loader/loader.service";
import {OnelabLocaleComponent} from "./shared/onelab-locale/onelab-locale.component";

// -- snackbar components
import {SnackbarComponent} from "./shared/snackbar/snackbar.component";
import {SnackbarSuccessComponent} from "./shared/snackbar-success/snackbar-success.component";
import {SnackbarErrorComponent} from "./shared/snackbar-error/snackbar-error.component";
import {SnackbarAlertComponent} from "./shared/snackbar-alert/snackbar-alert.component";

// -- product registration components
import {ProdRegExcelStepperComponent} from "./prodreg/prodreg.excel.stepper/prodreg.excel.stepper.component";
import {ProdRegGetAllComponent} from "./prodreg/prodreg.getall/prodreg.getall.component";
import {ProdRegLogoComponent} from "./prodreg/prodreg.logo/prodreg.logo.component";
import {ProdRegUpdateComponent} from "./prodreg/prodreg.update/prodreg.update.component";
import {ProdRegExcelService} from "./prodreg/prodreg.services/prodreg.excel.service";
import {ProdRegUpdateService} from "./prodreg/prodreg.services/prodreg.update.service";

// -- order components
import {OrdersTasksService} from "./orders/orders.tasks/orders.tasks.service";
import {OrdersExcelService} from "./orders/orders.excel/orders.excel.service";
import {OrdersNewTableService} from "./orders/orders.services/orders.new.table.service";

import {UpdatePasswordComponent} from "./settings/updatepassword/updatepassword.component";
import {QrCodeReaderService} from "./shared/qr-code-reader.service";
import {ToastMsgInterceptService} from "./shared/toast-msg-intercept.service";
import {OnelabUserSettingsComponent} from "./shared/onelab-user-settings/onelab-user-settings.component";
import { RegisterUserAndCompanyComponent } from './register-user-and-company/register-user-and-company.component';

export function DataAccessServiceProvider(http: HttpClient) {
  return new DataAccessService(http);
}

const l10nConfig: L10nConfig = L10N_CONFIG; // Angular L10n configuration -- localization library

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    SecureComponent,
    OnelabInputPasswordComponent,
    OnelabInputGeneralComponent,
    OnelabTitleComponent,
    OnelabUserSettingsComponent,
    OnelabNavListItemComponent,
    OnelabNavListGroupComponent,
    OnelabRenderSideNavComponent,
    HomeComponent,
    LogInComponent,
    LogOutComponent,
    RegisterComponent, 
    UserActivationComponent,
    TwoFactorAuthComponent,
    UpdatePasswordComponent,
    ResetPasswordRequestComponent,
    ResetPasswordUpdateComponent,

    ProdRegExcelStepperComponent,
    ProdRegGetAllComponent,
    ProdRegLogoComponent,
    ProdRegUpdateComponent,

    SnackbarComponent,
    SnackbarSuccessComponent,
    SnackbarErrorComponent,
    SnackbarAlertComponent,
    LoaderComponent,
    OnelabLocaleComponent,
    RegisterUserAndCompanyComponent, 
  ],
  entryComponents: [
    // OrdersExcelComponent,
    // OrdersPecaComponent,
    // ContentAdGetAllDeleteComponent,
    ProdRegLogoComponent,
    SnackbarSuccessComponent,
    SnackbarErrorComponent,
    SnackbarAlertComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HttpClientModule,
    FlexLayoutModule,
    FileDropModule,
    OnelabMaterialModule,
    NgxChartsModule,
    ChartsModule,
    TranslationModule.forRoot(l10nConfig)
  ],
  providers: [
    CanActivateViaAuthGuard,
    CanActivateViaPermissionGuard,
    CanActivateViaRoleGuard,
    { provide: DataAccessService, useFactory: DataAccessServiceProvider, deps: [HttpClient]},
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ToastMsgInterceptService, multi: true },
    OrdersTasksService,
    OrdersExcelService,
    OrdersNewTableService,
    ProdRegExcelService,
    ProdRegUpdateService,
    LoaderService,
    QrCodeReaderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public l10nLoader: L10nLoader) {
    this.l10nLoader.load();
  }
}
