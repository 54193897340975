/**
 * Created by camsongiang on 2017-02-27.
 */
import { Component } from "@angular/core";

@Component({
  selector: "app-public",
  templateUrl: "public.component.html",
  styleUrls:  ["../shared/share.style.css", "public.component.css"]
})
export class PublicComponent {

  // these are the config options for SimpleNotificationComponent
  public options = {

  };
}
