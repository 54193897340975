/**
 * File: prodreg.excel.image
 * Created by Ricky Leung on 2018-09-23
 */
export const PRODREG_EXCEL_IMAGE = {
  value: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA" +
  "JcEhZcwAAHYcAAB2HAY/l8WUAAAZ+SURBVFhH7ZhpUBNnGID7v78609FRR8cZHS1eo6AyCkqpOlMBT0ARQU5H0XqAeKBoRbGAHEI0Si1CQeX21hFvIEA" +
  "SyB0Scl8QjkDukyQk/Ta7Eg9a3VpBOzyzk3nf79vNPoFv3313v3F+kYxrocGtVXJD7u3fui2asXXHqG5h0Qyf1W3ZBRLEwwWipVJZfwogwfGYEBHPZLL" +
  "0SDKs1c7SX8BK4XhMqL3b97JBhSTDWnyBMRszllrl1T31uBG1Cr4qrT172xf6EFKzJPXPFEUVvWBEyNYmnRaCID9PuMiHEBTOHOg2JZ8RgRGweKFjnE4" +
  "/f0JgKM1/bVtKhnjIYlsTSFq8grgvhQ/Pvg86LWyB8JdUyMBost/4U5aB7QQxk6SK2McVsTXeP1PgKTZFvSdFsGsn/QVBBx3mIvV4Rz0ZWsV+P+JpfDM" +
  "IwrdRah4rXZPvgk4rPob+goicCWhlXekCAYusjk/mV93ozLwMpQAuQ+OzqnXFBhqcwhw7wn7UqAHB9xNewSOl16TnLkI/7H3QaZ0+yfkNi5wbaBWUdIO" +
  "gg6KOPsglNPYHRbTDUyyKeu8JYfKB9tI7/fAI4Mhh1sMGSGve/MZ+owMEsdHU20/d534TlGvLPuS3irjUj5hwjA/WlqcvcclKIoGojj3IBZMxMfQFywi" +
  "+AZRuqSHGNTJjQZPddRwg9URHXROkRW1Tz/VqXrqSCB81IqiX/OgwroWG/4sWtVUVGoNccaC0btnBSDkLVbK+LtP2OGZYDLPYVWM/EdRakZG0kO20Zpo" +
  "BxBOnNYDPwouizfFsuUAfEs8G6cZNpJJbCmjXTwCllt0eGNGu7jbGHeKBbMoMHDw8aQF+QGYIT+gAMb6hPy7pb6/8jwSd1q2qruVrSLG726fNawbp1Jm" +
  "QVhdft2QttU+sjzoA2eRk8M4UyKC9PwF0Wp5eOJMr2BlLe0bUzpzV4B9IWuRL7FRYRWztpFk439Wt67YxXLt8EqjX1ugwroWG/4UWjaxJSuGeSBfaHE4" +
  "6VXMohXv0FH9AbQNTLY0DYVGMhCSO2eZwDNqPpPISj3FrH0KNTQtO+czVO9Q97mtjQgUPgxFvCqe/alTuT+akZQiTjnGrXXsOg06rqFCSWyRveaXYGMu" +
  "+XdmVX9LNJCn9gxnGAfMP3kTQQ9U96FkWRHNarF5ryHqdNTiYXFyrqCiVZboaxvQ0zp3n6muF4mhXKcER1EqldXMw+d4LlUYH/bZh0GmVFcvSMbKyEll" +
  "CiuDR3e6I3ez9iezaOuXNUln270h7+N3kRqfD7reJDmIJVwdK/71aeV6RHKTnM3hPmrWhISSWxOLaFyIqksoUuVMYdFo3SztDopnXq3pAfL1ElontTNj" +
  "NbKIa7tfKE9OgZwqn0zF5Pt5pt4E/IUiePuzZc1xQW9GVdw3qYzPP8R43aWN2UF+2uR9NIyOocGv/Jui0sAUi+N8Bx1mFcqdxcOKcFpB6LGxKPM7zXIY" +
  "HfbBVY5k0GxcRy5izFK+zOAwqy/S5TUdP8mYtgu4NfJZ2qgcOrKddydAdbP26tlY2XKTdoNMaEat1CA4EQqMNCZ0229Dg63EYHt+IRC74QkTF4YD6+nf" +
  "4D7Q+B+NaaECnxaBpyO2GTokx9xIyK5UYcjDIu6iaankbHbnEhDx98nHuiTSBUmcHR124JE0/L1Jqhx/PPgA6rauXxZjSnjvV8jmLW67fhepyWBhl3uL" +
  "mQWjS4b2SsDyICiIRRzfDEy/uNDPoWibPlHuefzZfymDqTIMjrO4RQadVUiQtrOi9VdlVVCb3XQee5YfC93JCQ8lgChT9J3hd+Fayzu78NbXjQb2GQdF" +
  "cwEr7NfYrl0ShUczk0wL4Sz6Gf6NVc7Pz9jM16ATj9rCILGNAQCuY2hJC2n2IG7CBXFjeW5Ar+KOqDwyeOsmpfKTCYoTl99+65X0QdFpXMKK84u6yIun" +
  "VSgW+XvHtFOgRY4l3c6/CssAPemekHzBPmA3VzOkeuBysLGA9qaZOhckTBIXRDxzmyAfeuvH9A+i0jAab3mg3m+x6E7R4+1VW8NnbZ7GYh7R65JQyCVI" +
  "nm1pUbC5UQo1Gu1hi4vINVvvnWVujxtemlXNxLLUqanpH0OLyjOey4UZlbCgt735e735/iWgBPLyaW0laodjEExhHcxOKTFS6bu6SFrPZ3YC4tUwme2a" +
  "eODtfkl0wulu+JCtPrOh33The49b6ohjX+niczr8Ae0jXWZ/JaO4AAAAASUVORK5CYII="
};
