import { Component, Input, Output, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Language, LocaleService, TranslationService } from "angular-l10n";
import { DataAccessService } from "../shared/dataAccess.service";
import { SnackbarComponent } from "../shared/snackbar/snackbar.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import * as _ from "lodash";
@Component({
  selector: "app-login",
  templateUrl: "./registerUserAndCompany.html",
  styleUrls: [
    "./register-user-and-company.component.css",
    "../shared/share.style.css",
  ],
  providers: [DataAccessService, SnackbarComponent],
})
export class RegisterUserAndCompanyComponent implements OnInit, AfterViewInit, OnDestroy {
  //Jaylen Woon 2022-07-11

  //Constructor
  constructor(
    public router: Router,
    private dataAccessService: DataAccessService,
    private snackBar: SnackbarComponent,
    public translation: TranslationService,
    public locale: LocaleService
  ) {}
    ngOnDestroy()
    {

    }
  ngOnInit() {
    this.manageCompanyCreateForm = new FormGroup(
      {
        companyName: new FormControl("", [Validators.required]),
        shipAddressOne: new FormControl("", [Validators.required]),
        shipAddressTwo: new FormControl(""),
        shipCity: new FormControl("", [Validators.required]),
        shipStateProvince: new FormControl("", [Validators.required]),
        shipCountry: new FormControl("", [Validators.required]),
        billAddressOne: new FormControl("", [Validators.required]),
        billAddressTwo: new FormControl(""),
        billCity: new FormControl("", [Validators.required]),
        billStateProvince: new FormControl("", [Validators.required]),
        billCountry: new FormControl("", [Validators.required]),
      },
      this.allRequiredControls
    );
  }

  //register user section

  @Input() email: string; // user's email
  @Input() password: string; // user's password
  @Language() lang: string;
  private _email: string = null;
  private _password: string = null;
  private _confirmPassword: string = null;
  private _username: string = null;
  private _realname: string = null;
  hide = true;
  @Output() isDisabledRegister: boolean;

  udpateRegister(event, email, password, confirmPassword, realname) {
    event.preventDefault();

    this._email = email;
    this._password = password;
    this._confirmPassword = confirmPassword;
    this._username = "";
    this._realname = realname;
  }

  registerSend() {
    if (!this._email || !this.validateEmail(this._email)) {
      this.snackBar.error("Please enter a valid email");
      return;
    } else if (!this._password || this._password.length < 8) {
      this.snackBar.error("Please enter a password at least 8 characters");
      return;
    } else if (this._password != this._confirmPassword) {
      this.snackBar.error("Confirm passwords doesn't match");
      return;
    }

    if (this._email != null && this._password != null) {
      this.dataAccessService
        .register(
          this._username,
          this._email,
          this._password,
          this._realname,
          "en"
        )
        .subscribe(
          (response) => {
            console.log(response);
            //   if POST /authenticate returned 200, navigate to a secure route...
            if (response.code === 200) {
              this.navigateToLogin(true);
            }
          },
          (error) => {
            this._email = null;
            this._password = null;

            this.snackBar.error(error.message);
          }
        );
    }
  }

  navigateToLogin(registered: boolean) {
    this.router.navigateByUrl(`/login`);

    if (registered) {
      this.snackBar.success("Register success! Please check your email.");
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkRequired(email, password, confirmPassword) {
    // if the email and password are both null, keep the "submit" button disabled (isDisabled = true)
    // this.isDisabled = ((email == null) && (password == null));
    this.isDisabledRegister =
      _.isNil(email) || _.isNil(password) || _.isNil(confirmPassword);
  }

  //register company section

  private localeObserve = this.locale.languageCodeChanged.asObservable();
  public manageCompanyCreateForm: FormGroup;
  isDisabled: boolean;

  ngAfterViewInit() {
    this.localeObserve.subscribe((response) => {
      if (!_.isNil(response)) {
        this.translation.loadTranslation().then(() => {});
      }
    });
  }
  hasError(controlName: string, errorName: string) {
    const aFormControl = this.manageCompanyCreateForm.controls[controlName];
    return aFormControl.hasError(errorName);
  }
  allRequiredControls(fg: FormGroup) {
    const allControlsFilled =
      !_.isEmpty(fg.get("companyName").value) &&
      !_.isEmpty(fg.get("shipAddressOne").value) &&
      !_.isEmpty(fg.get("shipCity").value) &&
      !_.isEmpty(fg.get("shipStateProvince").value) &&
      !_.isEmpty(fg.get("shipCountry").value) &&
      !_.isEmpty(fg.get("billAddressOne").value) &&
      !_.isEmpty(fg.get("billCity").value) &&
      !_.isEmpty(fg.get("billStateProvince").value) &&
      !_.isEmpty(fg.get("billCountry").value);

    return allControlsFilled
      ? { disableSubmit: false }
      : { disableSubmit: true };
  }

  SameAsShipAddress(event) {
    const isSame = event.checked; // true = billing address is same as shipping, false = different
    const baseFormControlNames = [
      "AddressOne",
      "AddressTwo",
      "City",
      "StateProvince",
      "Country",
    ];

    if (isSame) {
      _.forEach(baseFormControlNames, (name) => {
        this.manageCompanyCreateForm.controls[`bill${name}`].setValue(
          this.manageCompanyCreateForm.controls[`ship${name}`].value
        );
        this.manageCompanyCreateForm.controls[`bill${name}`].disable();
      });
    } else {
      _.forEach(baseFormControlNames, (name) => {
        this.manageCompanyCreateForm.controls[`bill${name}`].enable();
        this.manageCompanyCreateForm.controls[`bill${name}`].setValue("");
      });
    }
  }

  ManageCompanyCreate(event) {
    event.preventDefault();

    const companyName = this.manageCompanyCreateForm.get("companyName").value;
    const shipAddress = {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      provinceState: "",
      country: "",
    };
    shipAddress.addressLineOne =
      this.manageCompanyCreateForm.get("shipAddressOne").value;
    shipAddress.addressLineTwo =
      this.manageCompanyCreateForm.get("shipAddressTwo").value;
    shipAddress.city = this.manageCompanyCreateForm.get("shipCity").value;
    shipAddress.provinceState =
      this.manageCompanyCreateForm.get("shipStateProvince").value;
    shipAddress.country = this.manageCompanyCreateForm.get("shipCountry").value;

    const billAddress = {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      provinceState: "",
      country: "",
    };
    billAddress.addressLineOne =
      this.manageCompanyCreateForm.get("billAddressOne").value;
    billAddress.addressLineTwo =
      this.manageCompanyCreateForm.get("billAddressTwo").value;
    billAddress.city = this.manageCompanyCreateForm.get("billCity").value;
    billAddress.provinceState =
      this.manageCompanyCreateForm.get("billStateProvince").value;
    billAddress.country = this.manageCompanyCreateForm.get("billCountry").value;

    this.dataAccessService
      .manageCompanyCreate(companyName, shipAddress, billAddress)
      .subscribe(
        (response) => {
          if (response.code === 200) {
            const msg = this.translation.translate(
              "manageCompany.snackCreated"
            );
            this.snackBar.success(msg);
          }
        },
        (error) => {
          let errMsg;
          switch (error.message.code) {
            case 11000:
              errMsg = this.SnackErrorMsg(companyName);
              break;
          }

          this.snackBar.error(errMsg);
        }
      );
  }

  SnackErrorMsg(companyName: string) {
    const format: string = this.translation.translate(
      "manageCompany.snackError"
    );
    return format.replace("[companyName]", companyName);
  }
  //joint data registration section
  registerUserAndCompany(event) {
    event.preventDefault();
    if (!this._email || !this.validateEmail(this._email)) {
      this.snackBar.error("Please enter a valid email");
      return;
    } else if (!this._password || this._password.length < 8) {
      this.snackBar.error("Please enter a password at least 8 characters");
      return;
    } else if (this._password != this._confirmPassword) {
      this.snackBar.error("Confirm passwords doesn't match");
      return;
    }

    const companyName = this.manageCompanyCreateForm.get("companyName").value;
    const shipAddress = {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      provinceState: "",
      country: "",
    };
    shipAddress.addressLineOne =
      this.manageCompanyCreateForm.get("shipAddressOne").value;
    shipAddress.addressLineTwo =
      this.manageCompanyCreateForm.get("shipAddressTwo").value;
    shipAddress.city = this.manageCompanyCreateForm.get("shipCity").value;
    shipAddress.provinceState =
      this.manageCompanyCreateForm.get("shipStateProvince").value;
    shipAddress.country = this.manageCompanyCreateForm.get("shipCountry").value;

    const billAddress = {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      provinceState: "",
      country: "",
    };
    billAddress.addressLineOne =
      this.manageCompanyCreateForm.get("billAddressOne").value;
    billAddress.addressLineTwo =
      this.manageCompanyCreateForm.get("billAddressTwo").value;
    billAddress.city = this.manageCompanyCreateForm.get("billCity").value;
    billAddress.provinceState =
      this.manageCompanyCreateForm.get("billStateProvince").value;
    billAddress.country = this.manageCompanyCreateForm.get("billCountry").value;
    if (this._email != null && this._password != null) {
      this.dataAccessService
        .manageCompanyCreate(companyName, shipAddress, billAddress)
        .subscribe(
          (responseCompany) => {
            if (responseCompany.code === 200) {
              const msg = this.translation.translate(
                "manageCompany.snackCreated"
              );
              this.snackBar.success(msg);
              console.log(responseCompany);
              this.dataAccessService
                .registerUnapproved(
                  responseCompany.message.id.toString(),
                  this._username,
                  this._email,
                  this._password,
                  this._realname,
                  "en"
                )
                .subscribe(
                  (responseUser) => {
                    console.log(responseUser);
                    //   if POST /authenticate returned 200, navigate to a secure route...
                    if (responseUser.code === 200) {
                      this.navigateToLogin(true);
                    }
                  },
                  (error) => {
                    this._email = null;
                    this._password = null;

                    this.snackBar.error("All fields for new company required");
                  }
                );
              //this.navigateToLogin(true);
            }
          },
          (error) => {
            let errMsg;
            switch (error.message.code) {
              case 11000:
                errMsg = this.SnackErrorMsg(companyName);
                break;
            }

            this.snackBar.error(errMsg);
          }
        );
    }
  }
}
