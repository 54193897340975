/**
 * File: onelab-locale.component
 * Created by Ricky Leung on 2019-05-04
 */
import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import { LocaleService, TranslationService, Language } from "angular-l10n";
import {SnackbarComponent} from "../snackbar/snackbar.component";

@Component({
  selector: "onelab-locale",
  templateUrl: "./onelab-locale.html",
  styleUrls: ["../share.style.css", "./onelab-locale.css"],
  providers: [SnackbarComponent]
})
export class OnelabLocaleComponent implements AfterViewInit,OnInit,OnDestroy {
  @Language() lang: string;
  private localeObserve = this.locale.languageCodeChanged.asObservable();

  constructor(public locale: LocaleService, public translation: TranslationService, private snackBar: SnackbarComponent) {}

  selectLanguage(language: string) {
    this.locale.setCurrentLanguage(language);
  }

  noteFromRicky() {
    this.snackBar.alert("Do not remove 'locale' from translation file! It is for this language select menu");
  }

  loadLocale(localeKey: string) {
    return this.translation.translate(localeKey);
  }
  ngOnInit(): void {
    
  }
  ngOnDestroy(): void {
    
  }
  ngAfterViewInit() {
    this.localeObserve.subscribe(response => {
      this.translation.loadTranslation().then(() => {});
    });
  }
}
