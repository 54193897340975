/**
 * Created by camsongiang on 2017-02-25.
 */
import { Injectable } from "@angular/core";
import {Observable, throwError, of} from "rxjs";
import { catchError, finalize, map, retry, retryWhen, shareReplay, tap } from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import { environment } from "../../environments/environment";
import {ITwoFactor} from "../models/itwofactor";
import {IAddress} from "../models/iaddress";
import * as _ from "lodash";

interface ItemResponse {
  code: number;
  type: string;
  message: any;
}

@Injectable()
export class DataAccessService {

  private environment = environment;
  private apiEndPointUrl = this.environment.apiEndpoint;
  private apiUsersUrl = this.apiEndPointUrl + "/users";
  private apiTwoFAUrl = this.apiEndPointUrl + "/2fa";
  private apiManageUrl = this.apiEndPointUrl + "/manage";
  private apiCompanyUrl = this.apiEndPointUrl + "/company";
  private apiConsumerUrl = this.apiEndPointUrl + "/consumer";
  private apiKolUrl = this.apiEndPointUrl + "/kol";

  constructor(public http: HttpClient) { }

  login(email: string, password: string, otp: string): Observable<any> {
    const url = this.apiUsersUrl + "/authenticate";
    const body = {
      email: email,
      password: password
    };
    let request: Observable<ItemResponse> = null;

    // if the OTP is not null, create request header with key 'x-otp'
    if (otp != null) {
      const options = {headers: new HttpHeaders().set("x-otp", otp)};
      request = this.http.post<ItemResponse>(url, body, options);
    } else {
      request = this.http.post<ItemResponse>(url, body);
    }

    // return request.map(response => this.handleLoginData(response)).catch(error => this.handleError(error));
    return request.pipe(
      map(response => this.handleLoginData(response)),
      catchError(error => this.handleError(error))
    );
  }
  registerUnapproved(company_id: string, username: string, email: string, password: string, name: string, languagecode: string): Observable<any> {
    const url = this.apiUsersUrl + "/registerUnapproved";
    const body = {
      "username": username,
      "email": email,
      "password": password,
      "name": name,
      "languagecode": languagecode,
      "company_id": company_id
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "registerUnapproved");
  }
  register(username: string, email: string, password: string, name: string, languagecode: string): Observable<any> {
    const url = this.apiUsersUrl + "/register";
    const body = {
      "username": username,
      "email": email,
      "password": password,
      "name": name,
      "languagecode": languagecode,
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "register");
  }

  activateUser(token: string): Observable<any> {
    const url = this.apiUsersUrl + "/activate";
    const body = {
      token: token
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "activate");
  }

  logout() {
    const localData = ["authData", "permissionData"];

    localData.forEach(data => {
      if (localStorage.getItem(data)) {
        localStorage.removeItem(data);
      }
    });
  }

  /* Refresh authentication token
  * */
  getNewAuthToken(url: string, options: Object): Observable<any> {
    console.log("-------- dataAccess getNewAuthToken");
    const request = this.http.get<ItemResponse>(url, options);
    return this.resolveRequestCommonResponse(request, "refreshToken");
  }

  /* Two Factor Authentication related methods
  * */
  get2FASetup(): Observable<any> {
    const url = this.apiTwoFAUrl + "/setup";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "get2FASetup");
  }

  create2FASetup(): Observable<any> {
    const url = this.apiTwoFAUrl + "/setup";
    const body = {};
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "create2FASetup");
  }

  verify2FA(otp: string): Observable<any> {
    const url = this.apiTwoFAUrl + "/verify";
    const body = {
      token: otp
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "verify2FA");
  }

  disable2FA(otp: string): Observable<any> {
    const url = this.apiTwoFAUrl + "/setup";
    const body = {
      token: otp
    };
    // const options = new RequestOptions({ body: body });
    const options = {params: new HttpParams().set("token", otp)};
    const request = this.http.delete<ItemResponse>(url, options);
    return this.resolveRequestCommonResponse(request, "disable2FA");
  }

  /* Reset Password related methods
  * */
  resetPasswordRequest(email: string): Observable<any> {
    const url = this.apiUsersUrl + "/resetpassword";
    const body = {
      email: email
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "resetPasswordRequest");
  }

  resetPasswordVerify(resetToken: string): Observable<any> {
    // const url = this.apiUsersUrl + "/resetpassword/" + resetToken;
    const url = this.apiUsersUrl + `/resetpassword/${resetToken}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "resetPasswordVerify");
  }

  resetPasswordUpdate(resetToken: string, newPassword: string): Observable<any> {
    const url = this.apiUsersUrl + "/resetpassword/savepassword";
    const body = {
      resettoken: resetToken,
      password: newPassword
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "resetPasswordUpdate");
  }

  /* Permission related methods
  * */
  getPermissions(): Observable<any> {
    const url = this.apiUsersUrl + "/permission";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "getPermissions");
  }

  getProfile(): Observable<any> {
    const url = this.apiUsersUrl + "/profile";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "getProfile");
  }

  userChangePassword(oldPassword: string, newPassword: string): Observable<any> {
    const url = this.apiUsersUrl + "/changepassword";
    const body = {
      oldpassword: oldPassword,
      newpassword: newPassword
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "userChangePassword");
  }

  /* Manage User related methods
  * */
  manageUserCreate(email: string, password: string, firstName: string, lastName: string, active: boolean,
                   roles: string[]): Observable<any> {
    const url = this.apiManageUrl + "/users";
    const body = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      active: active,
      roles: roles
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "manageUserCreate");
  }

  manageUnapprovedUserCreate(email: string, password: string, firstName: string, lastName: string, active: boolean,
    roles: string[]): Observable<any> {
  const url = this.apiManageUrl + "/users";
  const body = {
  email: email,
  password: password,
  firstName: firstName,
  lastName: lastName,
  active: active,
  roles: roles
  };
const request = this.http.post<ItemResponse>(url, body);
return this.resolveRequestCommonResponse(request, "manageUnapprovedUserCreate");
}

  manageUserUpdate(id: string, email: string, firstName: string, lastName: string, active: boolean, roles: string[],
                   twofactor: ITwoFactor | null): Observable<any> {
    // const url = this.apiManageUrl + "/users/" + id;
    const url = this.apiManageUrl + `/users/${id}`;
    const body = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      active: active,
      roles: roles,
      twofactor: twofactor
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "manageUserUpdate");
  }

  manageUserGetAll(): Observable<any> {
    const url = this.apiManageUrl + "/users";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageUserGetAll");
  }

  manageUserGetOne(userId: string): Observable<any> {
    const url = `${this.apiManageUrl}/users/${userId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageUserGetOne");
  }

  manageUserUpdateCompanyId(userId: string, companyId: string): Observable<any> {
    const url = `${this.apiManageUrl}/users/${userId}/company`;
    const body = {
      company_id: companyId
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "manageUserUpdateCompanyId");
  }

  /* Manage Company related methods
  * */
  manageCompanyCreate(companyName: string, shipAddress, billAddress): Observable<any> {
    const url = this.apiManageUrl + "/companiesAdd";
    const body = {
      name: companyName,
      addresses: [
        {
          address_type: "Physical",
          first_line: shipAddress.addressLineOne,
          second_line: shipAddress.addressLineTwo,
          city: shipAddress.city,
          state_prov: shipAddress.provinceState,
          country: shipAddress.country
        },
        {
          address_type: "Billing",
          first_line: billAddress.addressLineOne,
          second_line: billAddress.addressLineTwo,
          city: billAddress.city,
          state_prov: billAddress.provinceState,
          country: billAddress.country
        }
      ]
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "manageCompanyCreate");
  }

  manageCompanyGetAll(): Observable<any> {
    const url = this.apiManageUrl + "/companies";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageCompanyGetAll");
  }

  manageCompanyGetOne(companyId: string): Observable<any> {
    const url = this.apiManageUrl + `/companies/${companyId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageCompanyGetOne");
  }

  /* Company Marketing Default Value related methods
  * */
  companyDefaultValueGet(): Observable<any> {
    const url = this.apiManageUrl + "/companies/defaultvalue";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyDefaultValueGet");
  }

  companyDefaultValueUpdate(requestBody: any): Observable<any> {
    const url = this.apiManageUrl + "/companies/defaultvalue";
    const body = requestBody;
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyDefaultValueUpdate");
  }

  manageCompanyUpdate(id: string, companyName: string, shipAddress, billAddress): Observable<any> {
    // const url = this.apiManageUrl + "/companies/" + id;
    const url = this.apiManageUrl + `/companies/${id}`;
    const body = {
      name: companyName,
      addresses: [
        {
          address_type: "Physical",
          first_line: shipAddress.addressLineOne,
          second_line: shipAddress.addressLineTwo,
          city: shipAddress.city,
          state_prov: shipAddress.provinceState,
          country: shipAddress.country
        },
        {
          address_type: "Billing",
          first_line: billAddress.addressLineOne,
          second_line: billAddress.addressLineTwo,
          city: billAddress.city,
          state_prov: billAddress.provinceState,
          country: billAddress.country
        }
      ]
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "manageCompanyUpdate");
  }

  getAllKYC(): Observable<any> {
    const url = this.apiCompanyUrl + `/kyc/getAll`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "getAllKYC");
  }

  manageComanyUpdateKYCStatus(id: string, status: string) {
    const url = this.apiCompanyUrl + `/kyc/status/${id}/${status}`;
    const request = this.http.put<ItemResponse>(url, {});
    return this.resolveRequestCommonResponse(request, "manageComanyUpdateKYCStatus");
  }

  manageCompanyGetKYC(id: string): Observable<any> {
    const url = this.apiCompanyUrl + `/kyc/${id}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageCompanyGetOne");
  }

  manageCompanyGetKYCFromComapnyId(companyId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/kyc/company/${companyId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "getKYCIdFromCompanyId");
  }

  manageCompanyUpdateKYC(companyId: string, company: any): Observable<any> {
    let body: any = {};
    Object.assign(body, company);
    body.name = company.companyName;
    body.company_id = companyId;

    if (body.company_id) {
      const url = this.apiCompanyUrl + `/kyc/${companyId}`;
      const request = this.http.put<ItemResponse>(url, body);
      return this.resolveRequestCommonResponse(request, "manageCompanyUpdateKYC");
    } else {
      const url = this.apiCompanyUrl + `/kyc/create`;
      const request = this.http.post<ItemResponse>(url, body);
      return this.resolveRequestCommonResponse(request, "manageCompanyUpdateKYC");
    }
  }

  manageCompanyGetAllUsers(companyId: string): Observable<any> {
    const url = `${this.apiManageUrl}/companies/${companyId}/users`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageCompanyGetAllUsers");
  }

  /* Manage Company Payment related methods
  * */
  manageCompanyPaymentsGetAll(companyId: string): Observable<any> {
    const url = this.apiManageUrl + `/companies/${companyId}/payments`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "manageCompanyPaymentsGetAll");
  }

  manageCompanyPaymentsUpdate(companyId: string, paymentId: string, requestBody): Observable<any> {
    const url = this.apiManageUrl + `/companies/${companyId}/payments/${paymentId}`;
    const request = this.http.put<ItemResponse>(url, requestBody);
    return this.resolveRequestCommonResponse(request, "manageCompanyPaymentsUpdate");
  }

  /* Company-Orders related methods
  * */
  companyOrdersCreate(totalLabels: number, poItems: any): Observable<any> {
    const url = this.apiCompanyUrl + "/order";
    const body = {
      total_labels: totalLabels,
      order_po: { order_items: poItems }
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyOrdersCreate");
  }

  companyOrdersUpdate(orderId: string, requestBody: any): Observable<any> {
    // const url = this.apiCompanyUrl + "/order/" + orderId;
    const url = this.apiCompanyUrl + `/order/${orderId}`;
    const body = requestBody;
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyOrdersUpdate");
  }

  companyOrdersGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + "/order";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyOrdersGetAll");
  }

  companyOrdersGetOne(orderId: string): Observable<any> {
    // const url = this.apiCompanyUrl + "/order/" + orderId;
    const url = this.apiCompanyUrl + `/order/${orderId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyOrdersGetOne");
  }

  companyOrdersDeleteOne(orderId: string): Observable<any> {
    // const url = this.apiCompanyUrl + "/order/" + orderId;
    const url = this.apiCompanyUrl + `/order/${orderId}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyOrdersDeleteOne");
  }

  /* Company-AdToken related methods
  * */
  companyAdTokenCreate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + "/adtoken";
    const body = requestBody;
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyAdTokenCreate");
  }

  companyAdTokenUpdate(adTokenId: string, requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/adtoken/${adTokenId}`;
    const body = requestBody;
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyAdTokenUpdate");
  }

  companyAdTokenGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + "/adtoken";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyAdTokenGetAll");
  }

  companyAdTokenGetOne(adTokenId: any): Observable<any> {
    const url = this.apiCompanyUrl + `/adtoken/${adTokenId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyAdTokenGetOne");
  }

  companyAdTokenDelete(adTokenId: any): Observable<any> {
    const url = this.apiCompanyUrl + `/adtoken/${adTokenId}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyAdTokenDelete");
  }

  /* Company-General Incentive related methods
  * */
  companyIncentiveUpdate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/incentive`;
    const body = requestBody;

    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyIncentiveUpdate");
  }

  companyIncentiveGet(): Observable<any> {
    const url = this.apiCompanyUrl + `/incentive`;

    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyIncentiveGet");
  }

  /* Company-Coupon related methods
  * */
  companyCouponUpdate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/coupon`;
    const body = requestBody;

    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyCouponUpdate");
  }

  companyCouponGet(): Observable<any> {
    const url = this.apiCompanyUrl + `/coupon`;

    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyCouponGet");
  }

  companyRebateUpdate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/rebate`;
    const body = requestBody;

    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyRebateUpdate");
  }

  companyRebateGet(): Observable<any> {
    const url = this.apiCompanyUrl + `/rebate`;

    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyRebateGet");
  }

  /* Company-Payment QR Config related methods
* */
  companyPaymentQRUpdate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/paymentqr`;
    const body = requestBody;

    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyPaymentQRUpdate");
  }

  companyPaymentQRGet(): Observable<any> {
    const url = this.apiCompanyUrl + `/paymentqr`;

    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyPaymentQRGet");
  }

  /* Company-wallet related methods
  * */
  companyWalletCreatePayment(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/payments`;
    const request = this.http.post<ItemResponse>(url, requestBody);
    return this.resolveRequestCommonResponse(request, "companyWalletCreatePayment");
  }

  companyWalletGetPayments(): Observable<any> {
    const url = this.apiCompanyUrl + `/payments`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyWalletGetPayments");
  }

  companyWalletGetTransactions(): Observable<any> {
    const url = this.apiCompanyUrl + `/transactions`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyWalletGetTransactions");
  }

  companyWalletDelPayment(payment_id: string): Observable<any> {
    const url = this.apiCompanyUrl + `/payments/${payment_id}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyWalletDelPayment");
  }

  companyPaymentCreate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/payments`;
    const request = this.http.post<ItemResponse>(url, requestBody);
    return this.resolveRequestCommonResponse(request, "companyPaymentCreate", true);
  }

  companyPaymentGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + `/payments`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyPaymentGetAll", true);
  }

  companyPaymentDelete(paymentId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/payments/${paymentId}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyPaymentDelete", true);
  }

  companyPaymentGetAllTx(): Observable<any> {
    const url = this.apiCompanyUrl + `/transactions`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyPaymentGetAllTx", true);
  }

  /* Company-Content Ad related methods
  * */
  companyContentAdCreate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad`;
    const body = requestBody;
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyContentAdCreate");
  }

  companyContentAdUpdate(contentId: string, requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad/${contentId}`;
    const body = requestBody;
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyContentAdUpdate");
  }

  companyContentAdGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyContentAdGetAll");
  }

  companyContentAdGetOne(contentId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad/${contentId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyContentAdGetOne");
  }

  companyContentAdDelete(contentId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad/${contentId}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyContentAdDelete");
  }

  companyContentAdRewards(contentId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad/${contentId}/rewards`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyContentAdRewards");
  }

  companyContentAdSummary(contentId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad/${contentId}/summary`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyContentAdSummary");
  }

  companyContentAdTransactions(contentId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/contentad/${contentId}/transactions`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyContentAdTransactions");
  }

  /* Company-Logo related methods
  * */
  companyLogoGetOne(logoId: string): Observable<any> {
    // const url = this.apiCompanyUrl + "/logo/" + logoId;
    const url = this.apiCompanyUrl + `/logo/${logoId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyLogoGetOne");
  }

  companyLogoCreate(stuff: any): Observable<any> {
    const url = this.apiCompanyUrl + "/logo";
    const body = {
      stuff: stuff
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyLogoCreate");
  }

  companyLogoUpdate(logoId: string, stuff: any): Observable<any> {
    // const url = this.apiCompanyUrl + "/logo/" + logoId;
    const url = this.apiCompanyUrl + `/logo/${logoId}`;
    const body = {
      stuff: stuff
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyLogoUpdate");
  }

  /* Company-address related methods
  * */
  companyAddressGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + "/address/list";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyAddressGetAll");
  }

  companyAddressGetOne(addressId: string): Observable<any> {
    // const url = this.apiCompanyUrl + "/address/" + addressId;
    const url = this.apiCompanyUrl + `/address/${addressId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyAddressGetOne");
  }

  companyAddressCreate(addressData: IAddress): Observable<any> {
    const url = this.apiCompanyUrl + "/address";
    const body = {
      addressLineOne: addressData.addressLineOne,
      addressLineTwo: addressData.addressLineTwo,
      city: addressData.city,
      provinceState: addressData.provinceState,
      postalZipCode: addressData.postalZipCode,
      country: addressData.country
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyAddressCreate");
  }

  companyAddressUpdate(addressId: string, stuff: any): Observable<any> {
    // const url = this.apiCompanyUrl + "/address/" + addressId;
    const url = this.apiCompanyUrl + `/address/${addressId}`;
    const body = {
      stuff: stuff
    };
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyAddressUpdate");
  }

  /* Company-info related methods
  * */
  companyInfoGet() {
    const url = this.apiCompanyUrl + "/info";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyInfoGet");
  }

  companyInfoCreate(stuff: any) {
    const url = this.apiCompanyUrl + "/info";
    const body = {
      stuff: stuff
    };
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyInfoCreate");
  }

  /* Company-details related methods
  * */
  companyDetailsGet(): Observable<any> {
    const url = this.apiCompanyUrl + "/details";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyDetailsGet");
  }

  companyDetailsUpdate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + "/details";
    const body = requestBody;
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyDetailsUpdate");
  }

  /* Company-budget related methods
  * */
  companyBudgetGet(): Observable<any> { 
    const url = this.apiCompanyUrl + "/budget";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyBudgetGet");
  }

  companyBudgetUpdate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + "/budget";
    const body = requestBody;
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyBudgetUpdate");
  }

  /* Company-product related methods
  * */
  companyProductCreate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + "/product";
    const emptyProductLogo = { filename: "", filetype: "", value: "" };
    const body = {
      Brand: _.isNil(requestBody.Brand) ? "" : requestBody.Brand,
      ProductName: _.isNil(requestBody.ProductName) ? "" : requestBody.ProductName,
      Style: _.isNil(requestBody.Style) ? "" : requestBody.Style,
      ItemDescription: _.isNil(requestBody.ItemDescription) ? "" : requestBody.ItemDescription,
      MaterialContent: _.isNil(requestBody.MaterialContent) ? "" : requestBody.MaterialContent,
      Category: _.isNil(requestBody.Category) ? "" : requestBody.Category,
      Group: _.isNil(requestBody.Group) ? "" : requestBody.Group,
      Fabric: _.isNil(requestBody.Fabric) ? "" : requestBody.Fabric,
      Color: _.isNil(requestBody.Color) ? "" : requestBody.Color,
      Size: _.isNil(requestBody.Size) ? "" : requestBody.Size,
      UPC: _.isNil(requestBody.UPC) ? "" : requestBody.UPC,
      UOM: _.isNil(requestBody.UOM) ? "" : requestBody.UOM,
      Price: _.isNil(requestBody.Price) ? 0 : _.toNumber(_.trim(requestBody.Price, "$")),
      productLogo: _.isNil(requestBody.productLogo) ? emptyProductLogo : requestBody.productLogo
    };

    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyProductCreate");
  }

  companyProductUpdate(productId: string, requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/product/${productId}`;
    const emptyProductLogo = { filename: "", filetype: "", value: "" };
    const body = {
      Brand: _.isNil(requestBody.Brand) ? "" : requestBody.Brand,
      ProductName: _.isNil(requestBody.ProductName) ? "" : requestBody.ProductName,
      Style: _.isNil(requestBody.Style) ? "" : requestBody.Style,
      ItemDescription: _.isNil(requestBody.ItemDescription) ? "" : requestBody.ItemDescription,
      MaterialContent: _.isNil(requestBody.MaterialContent) ? "" : requestBody.MaterialContent,
      Category: _.isNil(requestBody.Category) ? "" : requestBody.Category,
      Group: _.isNil(requestBody.Group) ? "" : requestBody.Group,
      Fabric: _.isNil(requestBody.Fabric) ? "" : requestBody.Fabric,
      Color: _.isNil(requestBody.Color) ? "" : requestBody.Color,
      Size: _.isNil(requestBody.Size) ? "" : requestBody.Size,
      UPC: _.isNil(requestBody.UPC) ? "" : requestBody.UPC,
      UOM: _.isNil(requestBody.UOM) ? "" : requestBody.UOM,
      Price: _.isNil(requestBody.Price) ? 0 : _.toNumber(_.trim(requestBody.Price, "$")),
      productLogo: _.isNil(requestBody.productLogo) ? emptyProductLogo : requestBody.productLogo
    };

    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyProductUpdate");
  }

  companyProductGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + "/product";
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyProductGetAll");
  }

  companyProductGetOne(productId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/product/${productId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyProductGetOne");
  }

  companyProductDeleteOne(productId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/product/${productId}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyProductDeleteOne");
  }

  companyEcommerceProductGetAll(): Observable<any> {
    const url = this.apiCompanyUrl + `/products`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyEcommerceProductGetAll");
  }

  companyEcommerceProductGetOne(productId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/products/${productId}`;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyEcommerceProductGetOne");
  }

  companyEcommerceProductCreate(requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/products`;
    const body = requestBody;
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyEcommerceProductCreate");
  }

  companyEcommerceProductUpdate(productId: string, requestBody: any): Observable<any> {
    const url = this.apiCompanyUrl + `/products/${productId}`;
    const body = requestBody;
    const request = this.http.put<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "companyEcommerceProductUpdate");
  }

  companyEcommerceDeleteUpdate(productId: string): Observable<any> {
    const url = this.apiCompanyUrl + `/products/${productId}`;
    const request = this.http.delete<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "companyEcommerceDeleteUpdate");
  }

  kolGetAll(): Observable<any> {
    const url = this.apiKolUrl + '/results';
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "kolGetAll");
  }

  kolGetOne(kolId: string): Observable<any> {
    const url = this.apiKolUrl + '/' + kolId;
    const request = this.http.get<ItemResponse>(url);
    return this.resolveRequestCommonResponse(request, "kolGetOne");
  }

  kolPostJob(
    body: {
      kol_id:string,
      content_type_video:Boolean,
      content_type_photo:Boolean,
      content_first_start_date:string,
      content_description:string,
      content_on_social_media:string[],
    }, 
    handleError?: (error: any) => void
  ): Observable<any> {
    const url = this.apiKolUrl + '/jobs';
    const request = this.http.post<ItemResponse>(url, body);
    return this.resolveRequestCommonResponse(request, "kolGetOne", false);
  }

  kolChangeStatus(token: string, status: string): Observable<any> {
    const url = this.apiKolUrl + `/jobs/${token}/${status}`;
    const request = this.http.get<ItemResponse>(url);
    console.log(request);
    return this.resolveRequestCommonResponse(request, "kolGetOne", false);
  }

  /* Handlers
   * */
  private handleTest(res: ItemResponse) {
    console.log("----------------- INSIDE dataAccess.service.ts:handleTest()");
    // const body = res.json();
    console.log(res);
    console.log("----------------- RETURNING FROM dataAccess.service.ts:handleTest()");
    return res;
  }

  /* Error handlers
  * */
  private handleError (error: HttpErrorResponse) {

    let errBody = null;
    let errMsg = null;

    if (error.error != null) {
      errBody = error.error;
    }

    // if we have an error message JSON object, send it back up to caller
    //  otherwise create a message from the error status and text
    //  if we can't do that something is drastically broken and is a server error
    if (_.isNil(errBody)) {
      if (_.isNil(error.status)) {
        errMsg = { message: "Server error" };
      } else {
        errMsg = { message: `${error.status} - ${error.statusText}` };
      }
    } else {
      errMsg = errBody;
    }

    return throwError(errMsg);
  }

  private handleGet2FAError (error: any) {
    let body;
    if (error.status === 404) {
      // console.log("---------------------- INSIDE handleGet2FAError :: status 404");
      body = this.create2FASetup(); // TODO: need to fix this
      return body;
    } else {
      // return Observable.empty();
      return Observable.create(observer => {
        observer.empty();
        observer.complete();
      });
    }
  }

  /* Response handlers
  * */
  private handleLoginData(res: ItemResponse): ItemResponse {
    if (res.code === 200) {
      const authData = res.message;  // deep copy of login response, just want 'message'

      if (localStorage.getItem("authData")) { // if localStorage already contains authData...
        localStorage.removeItem("authData");  // remove previous authData from localStorage
      }
      localStorage.setItem("authData", JSON.stringify(authData)); // store new authData to localStorage
    }

    return res;
  }

  private handleCommonResponse(res: ItemResponse, caller: string, debug: boolean) {
    if (debug) {
      console.log(`----- INSIDE dataAccess ${caller} -----`);
      console.log(res);
    }
    return res;
  }

  private resolveRequestCommonResponse(
    request: Observable<ItemResponse>, 
    caller: string, 
    debug: boolean = false, 
    handleError?: (err: any) => void
  ) {
    return request.pipe(
      //tap(() => console.log("HTTP request executed")),
      map(response => this.handleCommonResponse(response, caller, debug)),
      catchError((error, caught) => {
        if (handleError) {
          handleError(error);
          return throwError(error);
        }
        return this.handleError(error); 
      })
    );
  }




}
