/**
 * Created by camsongiang on 2017-02-27.
 */

import { Routes } from "@angular/router";
import {LogInComponent} from "../login/login.component";
import {ResetPasswordRequestComponent} from "../resetpasswordrequest/resetpasswordrequest.component";
import {ResetPasswordUpdateComponent} from "../resetpasswordupdate/resetpasswordupdate.component";
import {RegisterComponent} from "../register/register.component";
import {UserActivationComponent} from "../register/useractivation.component";
import { RegisterUserAndCompanyComponent } from "../register-user-and-company/register-user-and-company.component";
//added register user and company route - Jaylen Woon
export const PUBLIC_ROUTES: Routes = [
  { path: "login", component: LogInComponent },
  { path: "resetpassword/request", component: ResetPasswordRequestComponent },
  { path: "resetpassword/update", component: ResetPasswordUpdateComponent },
  { path: "activation", component: UserActivationComponent},
  { path: "register", component: RegisterComponent}, 
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "registerUserAndCompany", component: RegisterUserAndCompanyComponent }
];
