/**
 * File: toastmsg.data
 * Created by Ricky Leung on 2020-03-21
 */
export const TOAST_MSG_DATA = [
  {
    method: "POST", route: "/company/order", description: "product/service order is created",
    statusCode: {
      "200": { type: "success", msgKey: "PRODUCT_ORDER_CREATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/order", description: "product/service order is updated",
    statusCode: {
      "200": { type: "success", msgKey: "PRODUCT_ORDER_UPDATE_SUCCESS" }
    }
  },
  {
    method: "DELETE", route: "/company/order", description: "product/service order is deleted",
    statusCode: {
      "200": { type: "success", msgKey: "PRODUCT_ORDER_DELETE_SUCCESS" }
    }
  },

  {
    method: "POST", route: "/company/adtoken", description: "ad token is created",
    statusCode: {
      "200": { type: "success", msgKey: "ADTOKEN_CREATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/adtoken", description: "ad token is updated",
    statusCode: {
      "200": { type: "success", msgKey: "ADTOKEN_UPDATE_SUCCESS" }
    }
  },
  {
    method: "DELETE", route: "/company/adtoken", description: "ad token is deleted",
    statusCode: {
      "200": { type: "success", msgKey: "ADTOKEN_DELETE_SUCCESS" }
    }
  },

  {
    method: "PUT", route: "/company/incentive", description: "general incentive is updated",
    statusCode: {
      "200": { type: "success", msgKey: "GENERAL_INCENTIVE_UPDATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/coupon", description: "coupon is updated",
    statusCode: {
      "200": { type: "success", msgKey: "COUPON_UPDATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/paymentqr", description: "payment qr is updated",
    statusCode: {
      "200": { type: "success", msgKey: "PAYMENT_QR_UPDATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/budget", description: "budget is updated",
    statusCode: {
      "200": { type: "success", msgKey: "BUDGET_UPDATE_SUCCESS" }
    }
  },

  {
    method: "POST", route: "/company/contentad", description: "content ad is created",
    statusCode: {
      "200": { type: "success", msgKey: "CONTENT_AD_CREATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/contentad", description: "content ad is updated",
    statusCode: {
      "200": { type: "success", msgKey: "CONTENT_AD_UPDATE_SUCCESS" }
    }
  },
  {
    method: "DELETE", route: "/company/contentad", description: "content ad is deleted",
    statusCode: {
      "200": { type: "success", msgKey: "CONTENT_AD_DELETE_SUCCESS" }
    }
  },

  {
    method: "POST", route: "/company/logo", description: "company logo is created",
    statusCode: {
      "200": { type: "success", msgKey: "COMPANY_LOGO_CREATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/logo", description: "company logo is updated",
    statusCode: {
      "200": { type: "success", msgKey: "COMPANY_LOGO_UPDATE_SUCCESS" }
    }
  },

  {
    method: "POST", route: "/company/address", description: "company address is created",
    statusCode: {
      "200": { type: "success", msgKey: "COMPANY_ADDRESS_CREATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/address", description: "company address is updated",
    statusCode: {
      "200": { type: "success", msgKey: "COMPANY_ADDRESS_UPDATE_SUCCESS" }
    }
  },

  {
    method: "POST", route: "/company/info", description: "company info is created",
    statusCode: {
      "200": { type: "success", msgKey: "COMPANY_INFO_CREATE_SUCCESS" }
    }
  },

  {
    method: "POST", route: "/company/product", description: "product/service is created",
    statusCode: {
      "200": { type: "success", msgKey: "PRODUCT_CREATE_SUCCESS" }
    }
  },
  {
    method: "PUT", route: "/company/product", description: "product/service is updated",
    statusCode: {
      "200": { type: "success", msgKey: "PRODUCT_UPDATE_SUCCESS" }
    }
  },
  {
    method: "DELETE", route: "/company/product", description: "product/service is deleted",
    statusCode: {
      "200": { type: "success", msgKey: "PRODUCT_DELETE_SUCCESS" }
    }
  },


];
