/**
 * Created by camsongiang on 2017-02-25.
 */
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {DataAccessService} from "../shared/dataAccess.service";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";

@Component({
  selector: "app-home",
  templateUrl: "../home/home.html",
  styleUrls:  ["../home/home.css"],
  providers : [ DataAccessService, SnackbarComponent ]
})
export class HomeComponent {

  constructor(public router: Router , private dataAccessService: DataAccessService,
              private snackBar: SnackbarComponent) {}

}
