/**
 * Project: authenticity
 * File: onelab-nav-list-group.component
 * Created by PCAPPS52 Ricky Leung on 1/15/2018 2:58 PM.
 * Description/Comments:
 */
import {Component, ElementRef, Input, Renderer2} from "@angular/core";
import {Subject} from "rxjs";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
  selector: "onelab-nav-list-group",
  templateUrl: "./onelab-nav-list-group.html",
  styleUrls: ["../share.style.css", "./onelab-nav-list-group.css"]
})
export class OnelabNavListGroupComponent {
  private _id: string;
  private _matIcon: string;
  private _navGroupLabel: string;

  stateChanges = new Subject<void>();
  focused = false;
  toggle = false;

  constructor(private fm: FocusMonitor, private elRef: ElementRef, private renderer: Renderer2) {
    // fm.monitor(elRef.nativeElement, renderer, true).subscribe((origin) => {
    //   this.focused = !!origin;
    //   this.stateChanges.next();
    // });
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

  }

  @Input()
  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
    this.stateChanges.next();
  }

  @Input()
  get matIcon() {
    return this._matIcon;
  }
  set matIcon(icn) {
    this._matIcon = icn;
    this.stateChanges.next();
  }

  @Input()
  get navGroupLabel() {
    return this._navGroupLabel;
  }
  set navGroupLabel(ngl) {
    this._navGroupLabel = ngl;
    this.stateChanges.next();
  }

}
