/**
 * File: dinput
 * Created by Ricky Leung on 2017-10-28
 */
export class DInput {
  data: string;
  constructor(data: string) {
    this.data = data;
  }
}
