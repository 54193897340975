/**
 * Created by camsongiang on 2017-02-27.
 */

import {Routes} from "@angular/router";
import {HomeComponent} from "../home/home.component";
import {TwoFactorAuthComponent} from "../settings/twofactorauth/twofactorauth.component";
import {LogOutComponent} from "../logout/logout.component";
import {ProdRegGetAllComponent} from "../prodreg/prodreg.getall/prodreg.getall.component";
import {ProdRegUpdateComponent} from "../prodreg/prodreg.update/prodreg.update.component";
import {ProdRegExcelStepperComponent} from "../prodreg/prodreg.excel.stepper/prodreg.excel.stepper.component";
import {UpdatePasswordComponent} from "../settings/updatepassword/updatepassword.component";

export const SECURE_ROUTES: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  { path: "welcome", component: HomeComponent },  // todo: sandbox page
  // { path: "sandbox", component: SandboxComponent }, // todo: sandbox page

  // -- Administrative function to manage companies
  { path: "companies", loadChildren: "./managecompanies/managecompanies.module#ManageCompaniesModule" },

  // -- Administrative function to manage users
  { path: "users", loadChildren: "./manageusers/manageusers.module#ManageUsersModule" },

  { path: "kol", loadChildren: "./kol/kol.module#KolModule" },

  // -- Customer function to create/read/update products
  // @note Ricky - Camson said to disable \product and \orders routes for now since we're not using this
  // { path: "product/register", component: ProdRegExcelStepperComponent },
  // { path: "product/register/getall", component: ProdRegGetAllComponent },
  // { path: "product/register/update", component: ProdRegUpdateComponent },
  // { path: "product/register",
  //   loadChildren: () => import("../prodreg/prodreg.module").then(mod => mod.ProdRegModule)},

  // -- Customer function for "orders"; need a major refactor since it's a hot mess
  // @note Ricky - Camson said to disable \product and \orders routes for now since we're not using this
  // { path: "orders", loadChildren: "./orders/orders.module#OrdersModule" },

  // -- Customer function for "adtoken"; was original implementation of "general incentive"
  { path: "adtoken", loadChildren: "./marketing/adtoken.module#AdTokenModule" },

  // -- Customer function to read/update general incentive
  { path: "incentive", loadChildren: "./marketing/incentive.module#IncentiveModule" },

  // -- Customer function to create/read/update content ads
  { path: "contentad", loadChildren: "./marketing/contentad.module#ContentAdModule", runGuardsAndResolvers: "always" },

  // -- Customer function to read/update coupon
  { path: "coupon", loadChildren: "./marketing/coupon.module#CouponModule" },

  // -- Customer function to read/update rebate
  { path: "rebate", loadChildren: "./marketing/rebate.module#RebateModule" },

  // -- Customer function to read/update marketing budget
  { path: "budget", loadChildren: "./marketing/budget.module#BudgetModule" },

  // -- Customer function to read reports
  { path: "reports", loadChildren: "./reports/reports.module#ReportsModule" },

  // -- Customer function to ecommerce
  { path: "ecommerce", loadChildren: "./ecommerce/ecommerce.module#EcommerceModule" },

  // -- Customer function to maintain company/account configuration
  { path: "account", loadChildren: "./companyaccount/companyaccount.module#CompanyAccountModule" },

  { path: "settings/twofactor", component: TwoFactorAuthComponent },
  { path: "settings/password", component: UpdatePasswordComponent },

  { path: "", redirectTo: "contentad", pathMatch: "full" },
  { path: "logout", component: LogOutComponent }
];
