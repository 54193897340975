/**
 * File: onelab-render-sidenav-roles.data
 * Created by Ricky Leung on 2019-04-27
 */
/* Notes on sidenav elements:
 *  groupEnable:
 *   This parameter signifies that this menu element is actually a group expand/collapse component.
 *   Set to 'false' and it is just a single-level, non-grouped nav menu item. Set to 'true' and it is rendered as a
 *   expand/collapse component
 *  groupToggle:
 *   This parameter keeps track of the expand/collapse state when the nav element is a group component.
 *   Set to 'false' and the group component is in the collapse state. Set to 'true' and the group component is in
 *   the expand state. Setting "groupEnable" to true to use this, otherwise it is ignored since it is only relevant
 *   when used with the group expand/collapse component
 *  groupMatIcon (Ref. https://material.io/icons/):
 *   Angular Material icon used to represent the group component. When nav element is not a group component, can be left
 *   as a blank string
 *  groupNavLabel:
 *   Label or description of the group. When nav element is not a group component, can be left as a blank string
 *  links:
 *   Array of links to render as nav items. When used in a group component, nav items will render in the order specified
 *   in the array. When used in a non-group, single-level nav menu, still use the array but only have one element
 *  routerLink:
 *   Secure link defined for the page/component we want to navigate to
 *  routerLinkActive:
 *   (actually need to look this up in the AngularJS Router documentation...)
 *  matIcon (Ref. https://material.io/icons/):
 *   Angular Material icon used to represent the nav item
 *  navLabel:
 *   Label or description of the nav item
 *  roles:
 *   Array of roles allowed to access this menu item
 * **/
export const ONELAB_RENDER_SIDENAV_ROLES_DATA = [
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "business_center",
    groupNavLabel: "Manage Companies",
    groupNavLabelKey: "SIDENAV_COMPANIES_GROUP",
    links: [
      {
        routerLink: "/companies",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Create New Company",
        navLabelKey: "SIDENAV_CREATE_NEW_COMPANY",
        roles: ["Admin"]
      },
      {
        routerLink: "/companies/getall",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Edit Companies",
        navLabelKey: "SIDENAV_VIEW_COMPANIES",
        roles: ["Admin"]
      },
      {
        routerLink: "/companies/viewkycinfo",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Manage KYC",
        navLabelKey: "SIDENAV_VIEW_KYC",
        roles: ["User", "Admin"]
      },
      {
        routerLink: "/companies/kycinfo",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Submit New Company With KYC",
        navLabelKey: "SIDENAV_SUBMIT_KYC",
        roles: ["User", "Admin"]
      },
      {
        routerLink: "/companies/marketingdefault",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Marketing Default",
        navLabelKey: "SIDENAV_MARKETING_DEFAULT",
        roles: [
          "Admin"
        ]
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "face",
    groupNavLabel: "Manage Users",
    groupNavLabelKey: "SIDENAV_USERS_GROUP",
    links: [
      {
        routerLink: "/users",
        routerLinkActive: "active",
        matIcon: "face",
        navLabel: "Create New User",
        navLabelKey: "CREATE_NEW_USER",
        roles: ["Admin"]
      },
      {
        routerLink: "/users/getall",
        routerLinkActive: "active",
        matIcon: "face",
        navLabel: "Edit Users",
        navLabelKey: "VIEW_USERS",
        roles: ["Admin"]
      },
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "face",
    groupNavLabel: "KOL",
    groupNavLabelKey: "SIDENAV_KOL_GROUP",
    links: [
      {
        routerLink: "/kol",
        routerLinkActive: "active",
        matIcon: "face",
        navLabel: "Search KOL",
        navLabelKey: "SIDENAV_SEARCH_KOL",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", 
          "Admin"
        ]
      },
    ]
  },
  // {
  //   groupEnable: true,
  //   groupToggle: false,
  //   groupMatIcon: "polymer",
  //   groupNavLabel: "Products",
  //   groupNavLabelKey: "SIDENAV_PRODUCTS_GROUP",
  //   links: [
  //     {
  //       routerLink: "/product/register",
  //       routerLinkActive: "active",
  //       matIcon: "polymer",
  //       navLabel: "Create Products",
  //       navLabelKey: "SIDENAV_PRODUCTS_CREATE",
  //       roles: [
  //         "BRAND_ADMIN",
  //         "BRAND_PROCUREMENT_MANAGER",
  //         "BRAND_MARKET_MANAGER",
  //         "BRAND_BUSINESS_ANALYST",
  //         "BRAND_ACCOUNTABLE",
  //         "BRAND_SYSTEM_ANALYST"
  //       ]
  //     },
  //     {
  //       routerLink: "/product/register/getall",
  //       routerLinkActive: "active",
  //       matIcon: "polymer",
  //       navLabel: "Update Products",
  //       navLabelKey: "SIDENAV_PRODUCTS_UPDATE",
  //       roles: [
  //         "BRAND_ADMIN",
  //         "BRAND_PROCUREMENT_MANAGER",
  //         "BRAND_MARKET_MANAGER",
  //         "BRAND_BUSINESS_ANALYST",
  //         "BRAND_ACCOUNTABLE",
  //         "BRAND_SYSTEM_ANALYST"
  //       ]
  //     }
  //   ]
  // },
  // {
  //   groupEnable: true,
  //   groupToggle: false,
  //   groupMatIcon: "shopping_cart",
  //   groupNavLabel: "Orders",  // todo: grouped "Orders" menu
  //   groupNavLabelKey: "SIDENAV_ORDERS_GROUP",
  //   links: [
  //     {
  //       routerLink: "/orders/create",
  //       routerLinkActive: "active",
  //       matIcon: "add_shopping_cart",
  //       navLabel: "Create Orders",
  //       navLabelKey: "SIDENAV_ORDERS_CREATE",
  //       roles: [
  //         "BRAND_ADMIN",
  //         "BRAND_PROCUREMENT_MANAGER",
  //         "BRAND_MARKET_MANAGER",
  //         "BRAND_BUSINESS_ANALYST",
  //         "BRAND_ACCOUNTABLE",
  //         "BRAND_SYSTEM_ANALYST"
  //       ]
  //     },
  //     {
  //       routerLink: "/orders/getall",
  //       routerLinkActive: "active",
  //       matIcon: "shopping_cart",
  //       navLabel: "Order Status",
  //       navLabelKey: "SIDENAV_ORDERS_STATUS",
  //       roles: [
  //         "BRAND_ADMIN",
  //         "BRAND_PROCUREMENT_MANAGER",
  //         "BRAND_MARKET_MANAGER",
  //         "BRAND_BUSINESS_ANALYST",
  //         "BRAND_ACCOUNTABLE",
  //         "BRAND_SYSTEM_ANALYST"
  //       ]
  //     }
  //   ]
  // },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "monetization_on",
    groupNavLabel: "Marketing",
    groupNavLabelKey: "SIDENAV_MARKETING_GROUP",
    links: [
      // {
      //   routerLink: "/budget/configure",
      //   routerLinkActive: "active",
      //   matIcon: "monetization_on",
      //   navLabel: "Configure Budget",
      //   navLabelKey: "SIDENAV_MARKETING_BUDGET",
      //   roles: [
      //     "Admin"
      //   ]
      // },
      // {
      //   routerLink: "/incentive/configure",
      //   routerLinkActive: "active",
      //   matIcon: "monetization_on",
      //   navLabel: "Configure General Incentive",
      //   navLabelKey: "SIDENAV_MARKETING_INCENTIVE",
      //   roles: [
      //     "Admin"
      //   ]
      // },
      // // {
      // //   routerLink: "/coupon/configure",
      // //   routerLinkActive: "active",
      // //   matIcon: "monetization_on",
      // //   navLabel: "Configure Coupon",
      // //   navLabelKey: "SIDENAV_MARKETING_COUPON",
      // //   roles: [
      // //     "BRAND_ADMIN",
      // //     "BRAND_PROCUREMENT_MANAGER",
      // //     "BRAND_MARKET_MANAGER",
      // //     "BRAND_BUSINESS_ANALYST",
      // //     "BRAND_ACCOUNTABLE",
      // //     "BRAND_SYSTEM_ANALYST"
      // //   ]
      // // },
      // {
      //   routerLink: "/rebate/configure",
      //   routerLinkActive: "active",
      //   matIcon: "monetization_on",
      //   navLabel: "Configure Rebate",
      //   navLabelKey: "SIDENAV_MARKETING_REBATE",
      //   roles: [
      //     "Admin"
      //   ]
      // },
      {
        routerLink: "/contentad",
        routerLinkActive: "active",
        matIcon: "monetization_on",
        navLabel: "Content Ad",
        navLabelKey: "SIDENAV_MARKETING_CONTENT_AD",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "business",
    groupNavLabel: "Ecommerce",
    groupNavLabelKey: "SIDENAV_ECOMMERCE_GROUP",
    links: [
      {
        routerLink: "/ecommerce/listing",
        routerLinkActive: "active",
        matIcon: "list_alt",
        navLabel: "Listing",
        navLabelKey: "SIDENAV_ECOMMERCE_LISTING",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "analytics",
    groupNavLabel: "Reports",
    groupNavLabelKey: "SIDENAV_REPORTS_GROUP",
    links: [
      {
        routerLink: "/reports/contentad",
        routerLinkActive: "active",
        matIcon: "analytics",
        navLabel: "Content Ad",
        navLabelKey: "SIDENAV_REPORTS_CONTENT_AD",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "business_center",
    groupNavLabel: "Account",
    groupNavLabelKey: "SIDENAV_COMPANYACCOUNT_GROUP",
    links: [
      // {
      //   routerLink: "/account/transactions",
      //   routerLinkActive: "active",
      //   matIcon: "business_center",
      //   navLabel: "Transactions",
      //   navLabelKey: "SIDENAV_COMPANYACCOUNT_TX",
      //   roles: [
      //     "BRAND_ADMIN",
      //     "BRAND_PROCUREMENT_MANAGER",
      //     "BRAND_MARKET_MANAGER",
      //     "BRAND_BUSINESS_ANALYST",
      //     "BRAND_ACCOUNTABLE",
      //     "BRAND_SYSTEM_ANALYST"
      //   ]
      // },
      {
        routerLink: "/account/merchantqr",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Merchant QR",
        navLabelKey: "SIDENAV_COMPANYACCOUNT_PAYMENTQR",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      },
      {
        routerLink: "/account/config",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Config",
        navLabelKey: "SIDENAV_COMPANYACCOUNT_CONFIG",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      },
      {
        routerLink: "/account/wallet",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Wallet",
        navLabelKey: "SIDENAV_COMPANYACCOUNT_Wallet",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      },
      {
        routerLink: "/account/transactions",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Transactions",
        navLabelKey: "SIDENAV_COMPANYACCOUNT_TX",
        roles: [
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST", "Admin"
        ]
      }
    ]
  },
  /*
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "settings",
    groupNavLabel: "Settings",
    groupNavLabelKey: "SIDENAV_SETTINGS_GROUP",
    links: [
      {
        routerLink: "/settings/twofactor",
        routerLinkActive: "active",
        matIcon: "security",
        navLabel: "Two-Factor Auth",
        navLabelKey: "SIDENAV_SETTINGS_2FA",
        roles: ["NONE"]
      },
      {
        routerLink: "/settings/password",
        routerLinkActive: "active",
        matIcon: "security",
        navLabel: "Update Password",
        navLabelKey: "PASSWORD_UPDATE",
        roles: [
          "Admin",
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST"
        ]
      }
    ]
  },*/
  /*{
    groupEnable: false,
    groupToggle: false,
    groupMatIcon: "",
    groupNavLabel: "",
    groupNavLabelKey: "",
    links: [
      {
        routerLink: "/logout",
        routerLinkActive: "active",
        matIcon: "close",
        navLabel: "Logout",
        navLabelKey: "SIDENAV_LOGOUT",
        roles: [
          "Admin",
          "BRAND_ADMIN",
          "BRAND_PROCUREMENT_MANAGER",
          "BRAND_MARKET_MANAGER",
          "BRAND_BUSINESS_ANALYST",
          "BRAND_ACCOUNTABLE",
          "BRAND_SYSTEM_ANALYST"
        ]
      }
    ]
  }*/
];
