/**
 * File: request-intercept.service
 * Created by Ricky Leung on 2017-12-23
 */
import {Injectable, Injector} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, Observable, ObservableInput, of, throwError} from "rxjs";
import {DataAccessService} from "./dataAccess.service";
import {LogOutComponent} from "../logout/logout.component";
import { catchError, map, tap, switchMap, finalize, filter, take } from "rxjs/operators";
import {ItemResponse} from "../models/itemResponse";


@Injectable()
export class RequestInterceptService implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get authData back from local storage
    const authData = JSON.parse(localStorage.getItem("authData"));

    if (authData != null) {
      // add authentication token to the request and let http handler remake request
      return next.handle(this.addAuthToken(req, authData.token))
        .pipe(
          catchError((error, caught) => {
            if (error instanceof HttpErrorResponse) {
              switch ((<HttpErrorResponse>error).status) {
                case 401: // handle unauthorized error; this is where we try to refresh the auth token
                  return this.handle401Error(req, next);
                case 400:
                  console.log("RequestInterceptService 400 error: " + error);
                  return this.handle400Error(error);
                case 404:
                  return this.handle404Error(error, req, next);
                default:  // show all http error response in frontend
                  console.log("RequestInterceptService switch error: " + error);
                  return throwError(error);
              }
            } else {
              //console.log("RequestInterceptService event error: " + error);
              return throwError(error);
            }
          })
        ) as any;

    } else if (authData == null) {
      return next.handle(req);
    }
  }

  private remoteAddress(requestUrl: string): string {
    const remoteAddressParts: string[] = [];
    remoteAddressParts.push((requestUrl.split("//"))[0]); // split and keep 'http:' part
    remoteAddressParts.push((((requestUrl.split("//"))[1]).split("/"))[0]); // split and keep {domain} part
    return remoteAddressParts.join("//"); // join the remote address parts with double forward-slash
  }

  private addAuthToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({headers: new HttpHeaders().set("Authorization", `Bearer ${token}`)});
  }

  private createTwoFactorAuth(req: HttpRequest<any>): HttpRequest<any> {
    const newReq = req.clone({method: "POST"});
    const authData = JSON.parse(localStorage.getItem("authData"));
    const currentToken = authData.token;
    return newReq.clone(this.addAuthToken(newReq, currentToken));
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // console.log("----------------- INSIDE handleUnauthorizedError");
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      // we need to refresh auth token before we can use it to authenticate
      // refresh auth token and pipe the observerable to get new auth token
      return this.refreshToken(req).pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addAuthToken(req, newToken));
          }
          return throwError("");
        }),
        catchError((error, caught) => {
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );

    } else {
      return this.tokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthToken(req, token));
        })
      );
    }
  }

  private handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === "invalid_grant") {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }

    return throwError(error);
  }

  private handle404Error(error, req: HttpRequest<any>, next: HttpHandler) {
    const reqUrl = req.url;
    const reqMethod = req.method;

    if (reqUrl.match("/2fa/setup") && reqMethod.match("GET")) {
      return next.handle(this.createTwoFactorAuth(req));
    } else {
      return throwError(error);
    }
  }

  private logoutUser() {
    // todo: logout the user if we hit this use case
    // todo: need a way to test this
    const logout = this.injector.get(LogOutComponent);
    logout.SignOut();
    // return observableThrowError("");
    // return Observable.throwError("");
    return throwError("");
  }

  private refreshToken(req: HttpRequest<any>): Observable<string> {
    // get current authData from local storage
    const authData = JSON.parse(localStorage.getItem("authData"));

    // use current request to get remote address and build renew token request url and header
    const url = this.remoteAddress(req.url) + `/users/renewtoken/${authData.email}`;
    const options = {headers: new HttpHeaders().set("Authorization", `Bearer ${authData.refresh_token}`)};
    let authTokenNew: string = null;

    localStorage.removeItem("authData");  // remove stale authData from local storage

    // use @angular/core injector to send in data access service
    const dataAccess = this.injector.get(DataAccessService);

    // create a new observable so that we can return new auth token
    return Observable.create(observer => {
      dataAccess.http.get<ItemResponse>(url, options).subscribe(response => {
        if (response.code === 200) {
          const newAuthData = response.message;

          if (localStorage.getItem("authData")) {
            localStorage.removeItem("authData");
          }
          localStorage.setItem("authData", JSON.stringify(newAuthData));
          authTokenNew = newAuthData.token;

          observer.next(authTokenNew);  // return the auth token and complete observer request
          observer.complete();
        } else {
          this.logoutUser();
          observer.complete();
        }
      });
    });
  }
}
