/**
 * File: prodreg.update.service
 * Created by Ricky Leung on 2018-08-13
 */
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ProdRegUpdateService {
  private _productData: any = {};
  private prodRegUpdateDataSource = new BehaviorSubject<any>(this._productData);
  currentProductData = this.prodRegUpdateDataSource.asObservable();

  constructor() {}

  resetProductData() {
    this.prodRegUpdateDataSource.next(this._productData);
  }

  updateProductData(productData) {
    this.prodRegUpdateDataSource.next(productData);
  }
}
