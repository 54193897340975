/**
 * File: signin.component
 * Created by Ricky Leung on 2017-10-28
 */
import {Component, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {DataAccessService} from "../shared/dataAccess.service";
import * as _ from "lodash";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";
import {Observable} from "rxjs";
import {Language,LocaleService, TranslationService} from "angular-l10n";

@Component({
  selector: "app-login",
  templateUrl: "login.html",
  styleUrls: ["../shared/share.style.css", "login.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class LogInComponent implements OnInit,OnDestroy {
  @Input() email: string;     // user's email
  @Input() password: string;  // user's password
  @Input() otp: string;       // one-time password for two-factor authentication

  @Output() isDisabled: boolean; // disable/enable the register button

  @Language() lang: string;

  twoFactorAuthEnabled = false;
  hide = true; // toggle password to show/hide value

  private _email: string = null;
  private _password: string = null;
  private _otp: string = null;
  _showSpinner = false;

  // constructor(public router: Router, private dataAccessService: DataAccessService,
  //             private _service: NotificationsService) {}
  constructor(public router: Router, private dataAccessService: DataAccessService,
              private translation: TranslationService,
              private snackBar: SnackbarComponent) {}

  // -- lifecycle hooks
  ngOnInit() {
    if (localStorage.getItem("authData")) {
      // console.log("----- already logged in");
      this.NavigateToDashboard();
      this.translation.loadTranslation().then(() => {});
    }
    this.isDisabled = true;
  }
  ngOnDestroy()
  {

  }
  // -- methods
  CheckRequired(email, password) {
    // if the email and password are both null, keep the "submit" button disabled (isDisabled = true)
    // this.isDisabled = ((email == null) && (password == null));
    this.isDisabled = (_.isNil(email) || _.isNil(password));
  }

  CheckRequired2FA(otp) {
    // if the otp is not 6 characters long, keep the "submit" button disabled (isDisabled = true)
    if (_.isNil(otp)) {
      this.isDisabled = true;
    } else {
      // this.isDisabled = (otp.data.length !== 6);
      this.isDisabled = (otp.length !== 6);
    }
  }

  /*
  * [2017/11/04 - Ricky Leung]
  * When signing in, several things will happen. User enters their email and password, and the "submit" button is enabled.
  * The user clicks the "submit" button which makes a request to API method POST /authenticate with the email and password
  * in the request body. If POST /authenticate returns code 200 "OK" just login and that's it. If POST /authenticate
  * returns code 206 "PARTIAL_CONTENT" that means two-factor authentication (2FA) is enabled. With 2FA enabled, we toggle
  * two booleans to update the page; one to disable the "submit" button, one to indicate 2FA is enabled which hides the
  * email and password input textboxes and shows the 2FA input textbox. After the user enters their 6-digit 2FA OTP, we
  * enable the "submit" button once more to allow the user to submit their login request. Again, we make a call to API
  * method POST /authenticate with the email and password in the request body, and include the OTP in the request
  * header as the value for the header key 'x-otp'. If the POST /authenticate returns code 200 "OK", login is successful
  * */
  SignIn(event, email, password, otp) {
    event.preventDefault();

    // if we haven't held onto the email and password yet...
    if ((this._email == null) && (this._password == null)) {
      this._email = email;
      this._password = password;
    }

    // if we haven't held onto the OTP yet and we now have a OTP value...
    if ((otp != null) && (this._otp == null)) {
      this._otp = otp;
    }

    // if we've held onto the email and password...
    if ((this._email != null) && (this._password != null)) {
      // let's try logging in...
      this._showSpinner = true;
      this.dataAccessService.login(this._email, this._password, this._otp)
        .subscribe(
          response => {
            this._showSpinner = false;
            // if POST /authenticate returned 206, 2FA is enabled...
            if (response.code === 206) {
              if (this.twoFactorAuthEnabled === false) {
                this.twoFactorAuthEnabled = true; // 2FA is enabled
                this.isDisabled = true; // disable the "submit" button until they enter 2FA
              }
            }

            // if POST /authenticate returned 200, navigate to a secure route...
            if (response.code === 200) {
              this.NavigateToDashboard();
            }
          },
          error => {
            this._showSpinner = false;
            // if 2FA is enabled...
            if (this.twoFactorAuthEnabled === true) {
              this._otp = null; // just reset the OTP and try again, we want to keep current email and password
            } else if (this.twoFactorAuthEnabled === false) { // else 2FA is disabled
              this._email = null;
              this._password = null;
            }
            //const msg="Incorrect user ID or password. Type the correct user ID and password, and try again.";
            this.snackBar.error(this.translation.translate("LOGIN_FAILED"));
          }
        );
    }
  }

  navigateToRegister() {
    //JYW
    //this.router.navigateByUrl("/register");
    this.router.navigateByUrl(`/registerUserAndCompany`);
  }

  // check the permission object, if our admin user, go to /welcome, everyone else go to /orders/create
  private NavigateToDashboard() {
    this.GetRouteUrl().subscribe(routeUrl => {
      this.router.navigateByUrl(routeUrl)
        .then(
          resolve => resolve,
          reject => reject
        )
        .catch(
          reject => reject
        );
    });
  }

  private GetRouteUrl(): Observable<string> {
    return Observable.create(observer => {
      this.dataAccessService.getPermissions()
        .subscribe(
          response => {
            if (response.code === 200) {
              observer.next("/welcome");
              observer.complete();

              // const permissionKeys = Object.keys(response.message.permissions);
              // if ((_.indexOf(permissionKeys, "MANAGE_COMPANIES") !== -1) ||
              //   (_.indexOf(permissionKeys, "MANAGE_USERS") !== -1)) {
              //   observer.next("/welcome");
              //   observer.complete();
              // } else {
              //   // observer.next("/orders/create");
              //   observer.next("/contentad");
              //   observer.complete();
              // }
            } else {
              // if we fail to get user permissions, force app to logout
              this.snackBar.error("No user permissions available");
              this.dataAccessService.logout();
            }
          },
          error => {
            // if we fail to get user permissions, force app to logout
            this.snackBar.error("Fail to get permissions");
            this.dataAccessService.logout();
          });
    });
  }

  NavToPasswordResetRequest() {
    this.router.navigateByUrl("/resetpassword/request")
      .then(
        resolve => resolve,
        reject => reject
      )
      .catch(
        reject => reject
      );

    // this.router.navigateByUrl("/resetpassword/request")
    //   .then(
    //     resolve => { return resolve; },
    //     reject => { return reject; }
    //   )
    //   .catch(
    //     reject => { return reject; }
    //   );
  }

}
