/**
 * File: signin.component
 * Created by Ricky Leung on 2017-10-28
 */
import {Component, Input, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {DataAccessService} from "../shared/dataAccess.service";
import * as _ from "lodash";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";
import {Observable} from "rxjs";
import {Language} from "angular-l10n";

@Component({
    selector: "app-login",
    templateUrl: "register.html",
    styleUrls: ["../shared/share.style.css", "register.css"],
    providers: [DataAccessService, SnackbarComponent]
})
export class RegisterComponent implements OnInit {
    @Input() email: string;     // user's email
    @Input() password: string;  // user's password

    @Language() lang: string;

    private _email: string = null;
    private _password: string = null;
    private _confirmPassword: string = null;
    private _username: string = null;
    private _realname: string = null;

    hide = true;
    @Output() isDisabled: boolean;

    constructor(public router: Router, private dataAccessService: DataAccessService,
                private snackBar: SnackbarComponent) {}

    ngOnInit() {
        if (localStorage.getItem("authData")) {
            // this.NavigateToDashboard();
        }
    }

    udpateRegister(event, email, password, confirmPassword, realname) {
        event.preventDefault();

        this._email = email;
        this._password = password;
        this._confirmPassword = confirmPassword;
        this._username = "";
        this._realname = realname;
    }
  
    registerSend() {
        
        if (!this._email || !this.validateEmail(this._email)) {
            this.snackBar.error("Please enter a valid email");
            return;
        } else if (!this._password || this._password.length < 8) {
            this.snackBar.error("Please enter a password at least 8 characters");
            return;
        } else if (this._password != this._confirmPassword) {
            this.snackBar.error("Confirm passwords doesn't match");
            return;
        } 

        if ((this._email != null) && (this._password != null)) {
            this.dataAccessService.register(this._username, this._email, this._password, this._realname, "en").subscribe(response => {
                console.log(response);
                //   if POST /authenticate returned 200, navigate to a secure route...
                  if (response.code === 200) {
                      this.navigateToLogin(true);
                  }
                },
                error => {
                    this._email = null;
                    this._password = null;
                    
                    this.snackBar.error(error.message);
                });
            }
  }

  navigateToLogin(registered: boolean) {
    this.router.navigateByUrl(`/login`);

    if (registered) {
        this.snackBar.success("Register success! Please login now");
    }
  }

  validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }   

  checkRequired(email, password, confirmPassword) {
    // if the email and password are both null, keep the "submit" button disabled (isDisabled = true)
    // this.isDisabled = ((email == null) && (password == null));
    this.isDisabled = (_.isNil(email) || _.isNil(password) || _.isNil(confirmPassword));
  }
}
