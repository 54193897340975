/**
 * File: loader.component
 * Created by Ricky Leung on 2019-03-18
 */
import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs/index";
import {LoaderService} from "./loader.service";
import {LoaderState} from "./loader.state";

@Component({
  selector: "onelab-loader",
  templateUrl: "./loader.html",
  styleUrls: ["../share.style.css", "./loader.css"]
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

