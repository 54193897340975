/**
 * File: signin.component
 * Created by Ricky Leung on 2017-10-28
 */
import {Component, Input, OnInit, Output} from "@angular/core";
import {Router, ActivatedRoute, Params} from "@angular/router";
import {DataAccessService} from "../shared/dataAccess.service";
import * as _ from "lodash";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";
import {Observable} from "rxjs";
import {Language} from "angular-l10n";

@Component({
    selector: "app-login",
    templateUrl: "useractivation.html",
    styleUrls: ["../shared/share.style.css"],
    providers: [DataAccessService, SnackbarComponent]
})
export class UserActivationComponent implements OnInit {

    status: string = "Activating user...";

    constructor(public router: Router, public route: ActivatedRoute, 
        private dataAccessService: DataAccessService,
        private snackBar: SnackbarComponent) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params: Params) => {
            console.log(params);
            if (!_.has(params, "token")) {
                this.status = "Failed to activate, please contact admin or try again later. ";
            } else {
                this.dataAccessService.activateUser(_.get(params, "token")).subscribe(response => {
                    if (response.code === 200) {
                        this.status = "Success! Your account is active now. ";
                    } else {
                        this.status = "Failed to activate, please contact admin or try again later. ";
                    }
                });
            }
        });
    }
}