/**
 * File: snackbar-error.component
 * Created by Ricky Leung on 2018-05-19
 */
import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material";

@Component({
  selector: "onelab-snackbar-error",
  templateUrl: "./snackbar-error.html",
  styleUrls: ["../share.style.css", "./snackbar-error.css", "../snackbar/snackbar.css"],
  // styleUrls: ["../share.style.css", "../snackbar/snackbar.css"],
  // encapsulation: ViewEncapsulation.None
})
export class SnackbarErrorComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
