/**
 * File: app.routes
 * Created by Ricky Leung on 2018-09-17
 */
import {RouterModule, Routes} from "@angular/router";
import {ModuleWithProviders} from "@angular/core";
import {PublicComponent} from "./layouts/public.component";
import {SecureComponent} from "./layouts/secure.component";
import {PUBLIC_ROUTES} from "./public/public.routes";
import {SECURE_ROUTES} from "./secure/secure.routes";
import {CanActivateViaAuthGuard} from "./routeguards/auth.canActivateGuard";
import {CanActivateViaPermissionGuard} from "./routeguards/permission.canActivateGuard";
import {CanActivateViaRoleGuard} from "./routeguards/role.canActivateGuard";

const APP_ROUTES: Routes = [
  { path: "", component: PublicComponent, data: { title: "Public Views" }, children: PUBLIC_ROUTES },
  { path: "", component: SecureComponent, data: { title: "Secure Views" }, children: SECURE_ROUTES,
    canActivate: [CanActivateViaAuthGuard, CanActivateViaPermissionGuard, CanActivateViaRoleGuard] }
];

// Export routes
export const routing: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES,
  { enableTracing: false, onSameUrlNavigation: "reload" });
