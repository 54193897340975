/**
 * File: toast-msg-intercept.service
 * Created by Ricky Leung on 2020-02-23
 */
import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {tap, filter} from "rxjs/operators";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material";
import {SnackbarSuccessComponent} from "./snackbar-success/snackbar-success.component";
import {SnackbarErrorComponent} from "./snackbar-error/snackbar-error.component";
import {SnackbarAlertComponent} from "./snackbar-alert/snackbar-alert.component";
import { environment } from "../../environments/environment";
import { TOAST_MSG_DATA } from "../shared.data/toastmsg.data";
import * as _ from "lodash";
import {Language, LocaleService, TranslationService} from "angular-l10n";

@Injectable()
export class ToastMsgInterceptService implements HttpInterceptor, OnInit,OnDestroy {
  @Language() lang: string;
  private localeObserve = this.locale.languageCodeChanged.asObservable();
  
  private duration = 2000;

  constructor(public snackBar: MatSnackBar,
              public locale: LocaleService,
              public translation: TranslationService)  {}
  ngOnInit(): void {
    
  }
  ngOnDestroy(): void {
    
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {

        if ((req.method !== "GET") && (event instanceof HttpResponse)) {
          const method = req.method;
          const url = event.url;
          const statusCode = _.toString(event.body.code);

          const route = this.getRequestRoute(environment.apiEndpoint, url);
          const findCondition = { method: method, route: route };
          const toastMsgData = _.find(TOAST_MSG_DATA, findCondition);

          if (toastMsgData) {
            // @ts-ignore: suppress statusCode error message
            const toaster = toastMsgData.statusCode[statusCode];
            this[toaster.type](toaster.msgKey);
          } else {
            // this.alert(`no toast! ${JSON.stringify(findCondition)}`);
            console.log(`no toast! ${JSON.stringify(findCondition)}`);
          }

        }

      })
    ) as any;
  }

  getRequestRoute(endpoint, url) {
    const urlSplit = url.split(endpoint);
    const routeSplit = (urlSplit[1]).split("/");
    const routeSplitLen = routeSplit.length;
    const id = (routeSplitLen === 3) ? null : routeSplit[routeSplitLen - 1];
    const routeSplitEnd = routeSplitLen - ((id === null) ? 0 : 1);
    const route = (routeSplit.slice(0, routeSplitEnd)).join("/");

    return route;
  }

  generalConfig(msgKey: string) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    const message = this.translation.translate(msgKey); // use msgKey to get translated phrase from asset

    config.data = (message === "No key") ? msgKey : message;
    config.duration = this.duration;
    return config;
  }

  success(msgKey: string) {
    const config = this.generalConfig(msgKey);
    this.snackBar.openFromComponent(SnackbarSuccessComponent, config);
  }

  error(msgKey: string) {
    const config = this.generalConfig(msgKey);
    this.snackBar.openFromComponent(SnackbarErrorComponent, config);
  }

  alert(msgKey: string) {
    const config = this.generalConfig(msgKey);
    this.snackBar.openFromComponent(SnackbarAlertComponent, config);
  }

}
