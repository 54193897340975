/**
 * File: updatepassword.component
 * Created by Ricky Leung on 2019-08-24
 */
import {AfterViewInit, Component, OnInit} from "@angular/core";
import {DataAccessService} from "../../shared/dataAccess.service";
import {SnackbarComponent} from "../../shared/snackbar/snackbar.component";
import {Router} from "@angular/router";
import * as _ from "lodash";
import {Language, LocaleService, TranslationService} from "angular-l10n";

@Component({
  selector: "onelab-updatepassword",
  templateUrl: "./updatepassword.html",
  styleUrls: ["../../shared/share.style.css", "./updatepassword.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class UpdatePasswordComponent implements OnInit, AfterViewInit {
  @Language() lang: string;
  private localeObserve = this.locale.languageCodeChanged.asObservable();
  isDisabled: boolean; // true = disable the 'update password' button

  hideOld = true;
  hideNew = true;
  hideConfirm = true;

  constructor(public router: Router, private dataAccessService: DataAccessService,
              private snackBar: SnackbarComponent,
              public translation: TranslationService,
              public locale: LocaleService) {}

  ngOnInit() {
    this.isDisabled = true;
  }

  ngAfterViewInit() {
    this.localeObserve.subscribe(response => {
      if (!_.isNil(response)) {
        this.translation.loadTranslation().then(() => {});
      }
    });
  }

  VerifyPassword(oldPassword, newPassword, confirmPassword): boolean {
    let isPasswordVerified = false; // old/new/confirm passwords is no good!

    if (!_.isNil(oldPassword) && !_.isNil(newPassword) && !_.isNil(confirmPassword) &&
      (oldPassword !== newPassword) && (oldPassword !== confirmPassword) && (newPassword === confirmPassword)) {
      isPasswordVerified = true;
    } else {
      isPasswordVerified = false;
    }

    return isPasswordVerified;
  }

  CheckRequired(oldPassword, newPassword, confirmPassword) {
    this.isDisabled = !this.VerifyPassword(oldPassword, newPassword, confirmPassword);
  }

  UpdatePassword(oldPassword, newPassword, confirmPassword) {
    if (this.VerifyPassword(oldPassword, newPassword, confirmPassword)) {
      this.dataAccessService.userChangePassword(oldPassword, newPassword)
        .subscribe(response => {
          if (response.code === 200) {
            this.snackBar.success(`Password updated`);
          }
        });
    }
  }

}
