/**
 * Project: authenticity
 * File: auth.canActivateGuard
 * Created by Ricky Leung on 1/12/2018 3:40 PM.
 * Description/Comments:
 */
import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

  constructor(public router: Router) {}

  canActivate() {
    if (localStorage.getItem("authData") === null) {
      this.router.navigateByUrl("/login");
    }

    return (localStorage.getItem("authData") !== null);

  }
}
