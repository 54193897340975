/**
 * File: onelab-nav-list-item.component
 * Created by Ricky Leung on 2017-11-18
 */
import {Component, ElementRef, Input, Renderer2} from "@angular/core";
import {Subject} from "rxjs";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
  selector: "onelab-nav-list-item",
  templateUrl: "./onelab-nav-list-item.html",
  styleUrls: ["../share.style.css", "./onelab-nav-list-item.css"]
})
export class OnelabNavListItemComponent {
  private _id: string;
  private _routerLink: string;
  private _routerLinkActive: string;
  private _matIcon: string;
  private _navLabel: string;
  private _spacer = 0;  // give this a default value, will be overridden when defined from the selector

  stateChanges = new Subject<void>();
  focused = false;

  constructor(private fm: FocusMonitor, private elRef: ElementRef, private renderer: Renderer2) {
    // fm.monitor(elRef.nativeElement, renderer, true).subscribe((origin) => {
    //   this.focused = !!origin;
    //   this.stateChanges.next();
    // });
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @Input()
  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
    this.stateChanges.next();
  }

  @Input()
  get routerLink() {
    return this._routerLink;
  }
  set routerLink(rl) {
    // TODO: [Ricky] need to revisit this in the future, check for leading forward-slash and append it if missing
    this._routerLink = rl;
    this.stateChanges.next();
  }

  @Input()
  get routerLinkActive() {
    return this._routerLinkActive;
  }
  set routerLinkActive(rla) {
    this._routerLinkActive = rla;
    this.stateChanges.next();
  }

  @Input()
  get matIcon() {
    return this._matIcon;
  }
  set matIcon(icn) {
    this._matIcon = icn;
    this.stateChanges.next();
  }

  @Input()
  get navLabel() {
    return this._navLabel;
  }
  set navLabel(nl) {
    this._navLabel = nl;
    this.stateChanges.next();
  }

  @Input()
  get spacer() {
    return this._spacer;
  }
  set spacer(spc) {
    this._spacer = spc;
    this.stateChanges.next();
  }


}
