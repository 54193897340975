/**
 * File: resetpasswordupdate.component
 * Created by Ricky Leung on 2017-11-26
 */
import {Component, OnInit, Output} from "@angular/core";
import {DataAccessService} from "../shared/dataAccess.service";
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {Observable} from "rxjs";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";
import { catchError, map, tap } from "rxjs/operators";
import * as _ from "lodash";

@Component({
  selector: "app-resetpasswordupdate",
  templateUrl: "resetpasswordupdate.html",
  styleUrls: ["../shared/share.style.css", "resetpasswordupdate.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class ResetPasswordUpdateComponent implements OnInit {

  @Output() tokenValid: boolean;
  private _resetToken: string = null;
  private _newPassword: string;
  isDisabled: boolean;

  hidePass = true;
  hideConfirm = true;

  constructor(public router: Router, public route: ActivatedRoute, private dataAccessService: DataAccessService,
              private snackBar: SnackbarComponent) {}

  // -- lifecycle hooks
  ngOnInit() {
    this.tokenValid = false;
    this.isDisabled = true;
    const errMessage = "Missing or invalid password reset token";

    this.route.queryParams.subscribe((params: Params) => {
      if (_.has(params, "token")) {
        const resetToken = _.get(params, "token");
        this._resetToken = resetToken;

        if (this._resetToken != null) {
          this.dataAccessService.resetPasswordVerify(resetToken)
            .subscribe(response => {
              if (response.code === 200) {
                this.tokenValid = true;
              }
            }, error => {
              this.snackBar.error(errMessage);
            });
        } else if (this._resetToken == null) {
          this.snackBar.error(errMessage);
        }
      }
    });

  }

  // -- methods
  CheckRequired(password, confirmPassword) {
    if (password === confirmPassword) {
      this._newPassword = password;
      this.isDisabled = false;
    } else {
      this._newPassword = null;
      this.isDisabled = true;
    }
  }

  UpdatePassword(event) {
    event.preventDefault();

    if (this._newPassword != null) {
      this.dataAccessService.resetPasswordUpdate(this._resetToken, this._newPassword)
        .subscribe(
          response => {
            if (response.code === 200) {
              this.router.navigateByUrl("/login")
                .then(
                  resolve => resolve,
                  reject => reject
                )
                .catch(
                  reject => reject
                );
            }
          },
          error => {
            // this._service.error("Oops!", error.message);
            this.snackBar.error(error.message);
          }
        );
    }
  }

  ReturnToLogin() {
    this.router.navigateByUrl("/login")
      .then(
        resolve => resolve,
        reject => reject
      )
      .catch(
        reject => reject
      );
  }

  ReturnToPasswordResetRequest() {
    this.router.navigateByUrl("/resetpassword/request")
      .then(
        resolve => resolve,
        reject => reject
      )
      .catch(
        reject => reject
      );
  }



}
