/**
 * Project: authenticity
 * File: resetpasswordrequest.component
 * Created by PCAPPS52 Ricky Leung on 11/23/2017 8:32 PM.
 * Description/Comments:
 */
import {Component, Input, Output, OnInit} from "@angular/core";
import {DataAccessService} from "../shared/dataAccess.service";
import {Router} from "@angular/router";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";

@Component({
  selector: "app-resetpasswordrequest",
  templateUrl: "resetpasswordrequest.html",
  styleUrls: ["../shared/share.style.css", "resetpasswordrequest.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class ResetPasswordRequestComponent implements OnInit {
  @Input() email: string;
  @Output() isDisabled: boolean;
  @Output() emailSent: boolean;
  @Output() userEmail: string;

  private _email: string = null;

  // constructor(public router: Router, private dataAccessService: DataAccessService,
  //             private _service: NotificationsService) {}
  constructor(public router: Router, private dataAccessService: DataAccessService,
              private snackBar: SnackbarComponent) {}

  // -- lifecycle hooks
  ngOnInit() {
    this.isDisabled = true;
    this.emailSent = false;
  }

  // -- methods
  CheckRequiredEmail(email) {
    this.isDisabled = (email == null);
  }

  ResetPasswordRequest(event, email) {
    event.preventDefault();

    if (this._email == null) {
      // this._email = email.data;
      this._email = email;
    }

    if (this._email != null) {
      this.dataAccessService.resetPasswordRequest(this._email)
        .subscribe(
          response => {
            console.log(response);
            if (response.code === 200) {
              // this._service.success("Success", response.message);
              this.snackBar.success(response.message);
              this.emailSent = true;
              this.userEmail = this._email;
            }
          },
          error => {
            // console.log("------------- INSIDE resetpasswordrequest.component ERROR");
            // console.log(error);

            // this._service.error("Oops!", error);
            this.snackBar.error(error);
            // alert(error);
          }
        );
    }
  }

  ReturnToLogin() {
    this.router.navigateByUrl("/login")
      .then(
        resolve => resolve,
        reject => reject
      )
      .catch(
        reject => reject
      );

    // this.router.navigateByUrl("/login")
    //   .then(
    //     resolve => { return resolve; },
    //     reject => { return reject; }
    //   )
    //   .catch(
    //     reject => { return reject; }
    //   );
  }

}
