/**
 * File: orders.excel.service
 * Created by Ricky Leung on 2018-03-24
 */
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class OrdersExcelService {
  private _purchaseOrderId = "";
  private dataSourcePurchaseOrderId = new BehaviorSubject<string>(this._purchaseOrderId);
  currentPurchaseOrderId = this.dataSourcePurchaseOrderId.asObservable();

  private _purchaseOrderItems: any = [];
  private dataSourcePurchaseOrderItems = new BehaviorSubject<any>(this._purchaseOrderItems);
  currentPurchaseOrderItems = this.dataSourcePurchaseOrderItems.asObservable();

  constructor() {}

  // -- reset methods
  resetPurchaseOrderId() {
    this.dataSourcePurchaseOrderId.next(this._purchaseOrderId);
  }

  resetPurchaseOrderItems() {
    this.dataSourcePurchaseOrderItems.next(this._purchaseOrderItems);
  }

  // -- update methods
  updatePurchaseOrderId(purchaseOrderId: string) {
    this.dataSourcePurchaseOrderId.next(purchaseOrderId);
  }

  updatePurchaseOrderItems(purchaseOrderItems: any) {
    this.dataSourcePurchaseOrderItems.next(purchaseOrderItems);
  }
}
