/**
 * File: orders.tasks.service
 * Created by Ricky Leung on 2018-03-24
 */
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class OrdersTasksService {
  private purchaseOrderId = "";
  private dataSourcePurchaseOrderId = new BehaviorSubject<string>(this.purchaseOrderId);
  currentPurchaseOrderId = this.dataSourcePurchaseOrderId.asObservable();

  private purchaseOrderTotalLabels = "";
  private dataSourcePurchaseOrderTotalLabels = new BehaviorSubject<string>(this.purchaseOrderTotalLabels);
  currentPurchaseOrderTotalLabels = this.dataSourcePurchaseOrderTotalLabels.asObservable();

  private purchaseOrderData = {};
  private ordersTaskDataSource = new BehaviorSubject<any>(this.purchaseOrderData);
  currentOrderData = this.ordersTaskDataSource.asObservable();

  constructor() {}

  // -- reset methods
  resetPurchaseOrderId() {
    this.dataSourcePurchaseOrderId.next(this.purchaseOrderId);
  }

  resetPurchaseOrderTotalLabels() {
    this.dataSourcePurchaseOrderTotalLabels.next(this.purchaseOrderTotalLabels);
  }

  resetPurchaseOrderData() {
    this.ordersTaskDataSource.next(this.purchaseOrderData);
  }

  // -- update methods
  updatePurchaseOrderId(purchaseOrderId: string) {
    this.dataSourcePurchaseOrderId.next(purchaseOrderId);
  }

  updatePurchaseOrderTotalLabels(totalLabels: string) {
    this.dataSourcePurchaseOrderTotalLabels.next(totalLabels);
  }

  updateOrderData(orderData: any) {
    this.ordersTaskDataSource.next(orderData);
  }
}
