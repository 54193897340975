/**
 * Project: authenticity
 * File: onelab-render-sidenav.data
 * Created by PCAPPS52 Ricky Leung on 2/5/2018 11:53 AM.
 * Description/Comments:
 */

/* Notes on sidenav elements:
 *  groupEnable:
 *   This parameter signifies that this menu element is actually a group expand/collapse component.
 *   Set to 'false' and it is just a single-level, non-grouped nav menu item. Set to 'true' and it is rendered as a
 *   expand/collapse component
 *  groupToggle:
 *   This parameter keeps track of the expand/collapse state when the nav element is a group component.
 *   Set to 'false' and the group component is in the collapse state. Set to 'true' and the group component is in
 *   the expand state. Setting "groupEnable" to true to use this, otherwise it is ignored since it is only relevant
 *   when used with the group expand/collapse component
 *  groupMatIcon (Ref. https://material.io/icons/):
 *   Angular Material icon used to represent the group component. When nav element is not a group component, can be left
 *   as a blank string
 *  groupNavLabel:
 *   Label or description of the group. When nav element is not a group component, can be left as a blank string
 *  links:
 *   Array of links to render as nav items. When used in a group component, nav items will render in the order specified
 *   in the array. When used in a non-group, single-level nav menu, still use the array but only have one element
 *  routerLink:
 *   Secure link defined for the page/component we want to navigate to
 *  routerLinkActive:
 *   (actually need to look this up in the AngularJS Router documentation...)
 *  matIcon (Ref. https://material.io/icons/):
 *   Angular Material icon used to represent the nav item
 *  navLabel:
 *   Label or description of the nav item
 *  permissionKey:
 *   Required key from user's permissions for sidenav to be included in render list
 *  permissionActions:
 *   Array of actions allowed for the user
 * **/
export const ONELAB_RENDER_SIDENAV_DATA = [
  // {  // todo: dev sandbox
  //   groupEnable: false,
  //   groupToggle: false,
  //   groupMatIcon: "",
  //   groupNavLabel: "",
  //   groupNavLabelKey: "",
  //   links: [
  //     {
  //       routerLink: "/dashboard",
  //       routerLinkActive: "active",
  //       matIcon: "home",
  //       navLabel: "Sandbox (tbd)",
  //       navLabelKey: "navSandbox",
  //       permissionKey: "NONE",
  //       permissionActions: []
  //     }
  //   ]
  // },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "business_center",
    groupNavLabel: "Manage Companies",
    groupNavLabelKey: "sidenav.companies.group",
    links: [
      {
        routerLink: "/manage/companies",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Create New Company",
        navLabelKey: "sidenav.companies.create",
        permissionKey: "MANAGE_COMPANIES",
        permissionActions: ["GET", "POST", "PUT", "DELETE", "*"]
      },
      {
        routerLink: "/manage/companies/getall",
        routerLinkActive: "active",
        matIcon: "business_center",
        navLabel: "Edit Companies",
        navLabelKey: "sidenav.companies.update",
        permissionKey: "MANAGE_COMPANIES",
        permissionActions: ["GET", "POST", "PUT", "DELETE", "*"]
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "face",
    groupNavLabel: "Manage Users",
    groupNavLabelKey: "sidenav.users.group",
    links: [
      {
        routerLink: "/manage/users",
        routerLinkActive: "active",
        matIcon: "face",
        navLabel: "Create New User",
        navLabelKey: "sidenav.users.create",
        permissionKey: "MANAGE_USERS",
        permissionActions: ["GET", "POST", "PUT", "DELETE", "*"]
      },
      {
        routerLink: "/manage/users/getall",
        routerLinkActive: "active",
        matIcon: "face",
        navLabel: "Edit Users",
        navLabelKey: "sidenav.users.update",
        permissionKey: "MANAGE_USERS",
        permissionActions: ["GET", "POST", "PUT", "DELETE", "*"]
      },
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "face",
    groupNavLabel: "Manage KOLs",
    groupNavLabelKey: "sidenav.kol.group",
    links: [
      {
        routerLink: "/kol/getall",
        routerLinkActive: "active",
        matIcon: "face",
        navLabel: "Find KOL",
        navLabelKey: "sidenav.kol.getall",
        permissionKey: "MANAGE_USERS",
        permissionActions: ["GET", "POST", "PUT", "DELETE", "*"]
      },
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "polymer",
    groupNavLabel: "Products",
    groupNavLabelKey: "sidenav.products.group",
    links: [
      {
        routerLink: "/product/register",
        routerLinkActive: "active",
        matIcon: "polymer",
        navLabel: "Create Products",
        navLabelKey: "sidenav.products.create",
        permissionKey: "NONE",
        permissionActions: []
      },
      {
        routerLink: "/product/register/getall",
        routerLinkActive: "active",
        matIcon: "polymer",
        navLabel: "Update Products",
        navLabelKey: "sidenav.products.update",
        permissionKey: "NONE",
        permissionActions: []
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "shopping_cart",
    groupNavLabel: "Orders",  // todo: grouped "Orders" menu
    groupNavLabelKey: "sidenav.orders.group",
    links: [
      {
        routerLink: "/orders/create",
        routerLinkActive: "active",
        matIcon: "add_shopping_cart",
        navLabel: "Create Orders",
        navLabelKey: "sidenav.orders.create",
        permissionKey: "NONE",  // todo: Orders need new permission key
        permissionActions: []
      },
      {
        routerLink: "/orders/getall",
        routerLinkActive: "active",
        matIcon: "shopping_cart",
        navLabel: "Order Status",
        navLabelKey: "sidenav.orders.status",
        permissionKey: "NONE",  // todo: Orders need new permission key
        permissionActions: []
      }
    ]
  },
  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "monetization_on",
    groupNavLabel: "Marketing",
    groupNavLabelKey: "sidenav.marketing.group",
    links: [
      {
        routerLink: "/incentive/configure",
        routerLinkActive: "active",
        matIcon: "monetization_on",
        navLabel: "General Incentive",
        navLabelKey: "sidenav.marketing.incentive",
        permissionKey: "NONE",
        permissionActions: []
      },
      {
        routerLink: "/coupon/configure",
        routerLinkActive: "active",
        matIcon: "monetization_on",
        navLabel: "Coupon",
        navLabelKey: "sidenav.marketing.coupon",
        permissionKey: "NONE",
        permissionActions: []
      },
      {
        routerLink: "/contentad/create",
        routerLinkActive: "active",
        matIcon: "monetization_on",
        navLabel: "Create Ad",
        navLabelKey: "sidenav.marketing.createAd",
        permissionKey: "NONE",
        permissionActions: []
      },
      {
        routerLink: "/contentad/getall",
        routerLinkActive: "active",
        matIcon: "monetization_on",
        navLabel: "Update Ads",
        navLabelKey: "sidenav.marketing.updateAd",
        permissionKey: "NONE",
        permissionActions: []
      }
    ]
  },

  {
    groupEnable: true,
    groupToggle: false,
    groupMatIcon: "settings",
    groupNavLabel: "Settings",
    groupNavLabelKey: "sidenav.settings.group",
    links: [
      {
        routerLink: "/settings/twofactor",
        routerLinkActive: "active",
        matIcon: "security",
        navLabel: "Two-Factor Auth",
        navLabelKey: "sidenav.settings.twoFactor",
        permissionKey: "DISABLE",
        permissionActions: []
      }
    ]
  },
  {
    groupEnable: false,
    groupToggle: false,
    groupMatIcon: "",
    groupNavLabel: "",
    groupNavLabelKey: "",
    links: [
      {
        routerLink: "/logout",
        routerLinkActive: "active",
        matIcon: "close",
        navLabel: "Logout",
        navLabelKey: "sidenav.logout",
        permissionKey: "NONE",
        permissionActions: []
      }
    ]
  }
];
