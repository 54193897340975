/**
 * File: prodreg.logo.component
 * Created by Ricky Leung on 2018-07-29
 */

import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnInit} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {IImage} from "../../models/iimage";
import {Router} from "@angular/router";
import {ProdRegExcelService} from "../prodreg.services/prodreg.excel.service";
import * as _ from "lodash";
import {FileSystemFileEntry, UploadEvent, UploadFile} from "ngx-file-drop";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: "onelab-prodreg-logo",
  templateUrl: "./prodreg.logo.html",
  styleUrls: ["../../shared/share.style.css", "./prodreg.logo.css"]
})
export class ProdRegLogoComponent implements OnInit, OnChanges, AfterViewInit {
  private imageDataSource = new BehaviorSubject<IImage>(null);
  private currentImageData = this.imageDataSource.asObservable();

  isDisabled: boolean;
  image: IImage = { filename: "", filetype: "", value: "" };

  constructor(public router: Router, private cdRef: ChangeDetectorRef,
              private prodRegExcelService: ProdRegExcelService,
              @Inject(MAT_DIALOG_DATA) private productData: any,
              private dialogRef: MatDialogRef<ProdRegLogoComponent>) {}

  ngOnInit() {
    this.isDisabled = true;
  }

  ngOnChanges() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.currentImageData.subscribe(response => {
      if (!_.isNil(response)) {
        this.isDisabled = false;
        this.image = response;
        this.cdRef.detectChanges();
      }
    });
  }

  OnFileDropEvent(event: UploadEvent) {
    const reader: FileReader = new FileReader();
    const img = new Image();
    const oneFile: UploadFile = event.files[0];

    if (oneFile.fileEntry.isFile) {
      const fileEntry = oneFile.fileEntry as FileSystemFileEntry;

      fileEntry.file((file: File) => {

        // FileReader -- handler for "load" event
        reader.onload = (readerEv: Event) => {
          const readerResult = _.toString(reader.result);

          // Image -- handler for "load" event
          img.onload = (imageEv: Event) => {
            const imageDataObject: IImage = {
              filename: file.name, filetype: file.type, value: readerResult
            };

            this.imageDataSource.next(imageDataObject);
            this.imageDataSource.complete();
          };

          img.src = readerResult;
        };
      });
    }
  }

  UpdateProdRegImage(event) {
    event.preventDefault();
    this.productData.Image = this.image.value; // assign the encoded image data to my product data
    this.productData.productLogo = this.image;
    this.dialogRef.close("Image updated!"); // send back dialog result
  }

}
