/**
 * Project: authenticity
 * File: permission.canActivateGuard
 * Created by Ricky Leung on 1/12/2018 3:53 PM.
 * Description/Comments:
 */
import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {DataAccessService} from "../shared/dataAccess.service";
import {Observable} from "rxjs";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";

@Injectable()
export class CanActivateViaPermissionGuard implements CanActivate {

  // constructor(public router: Router, private dataAccessService: DataAccessService,
  //             private snackBar: SnackbarComponent) {}

  constructor(public router: Router, private dataAccessService: DataAccessService) {}

  canActivate() {
    // every time someone login, destroy any existing permission data
    localStorage.removeItem("permissionData");

    // create a new observable so that we return the get permission response
    return Observable.create(observer => {
      if (localStorage.getItem("permissionData") === null) {
        this.dataAccessService.getPermissions()
          .subscribe(
            response => {
              const permissionData = response.message;
              localStorage.setItem("permissionData", JSON.stringify(permissionData));
              observer.next(localStorage.getItem("permissionData") !== null);
              observer.complete();
            },
            error => {
              // console.log("++ permission.canActivateGuard - error");
              // console.log(error);
              // this._service.error("Oops!", error.message);
              // this.snackBar.error(error.message);
            }
          );
      }
    });

  }
}
