/**
 * File: onelab-title.component
 * Created by Ricky Leung on 2017-11-18
 */
import {Component} from "@angular/core";

@Component({
  selector: "onelab-title",
  templateUrl: "./onelab-title.html",
  styleUrls: ["../share.style.css", "./onelab-title.css"]
})
export class OnelabTitleComponent {
  title = "OnePi";
  // matIcon = "android";
  matIcon = "cake";
  logo ="./assets/1PiLogo.png";

  constructor() {}
}
