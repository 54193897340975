/**
 * File: prodreg.excel.stepper.component
 * Created by Ricky Leung on 2018-08-18
 */
import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {SnackbarComponent} from "../../shared/snackbar/snackbar.component";
import {DataAccessService} from "../../shared/dataAccess.service";
import {WorkSheet} from "xlsx";
import {ProdRegExcelService} from "../prodreg.services/prodreg.excel.service";
import {Router} from "@angular/router";
import {FileSystemFileEntry} from "ngx-file-drop";
import * as xlsx from "xlsx";
import * as _ from "lodash";
import {Observable} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {ProdRegLogoComponent} from "../prodreg.logo/prodreg.logo.component";
import {ProdRegExcelStepperDataSource} from "./prodreg.excel.stepper.datasource";
import {Language, LocaleService, TranslationService} from "angular-l10n";

@Component({
  selector: "onelab-prodreg-excel-stepper",
  templateUrl: "./prodreg.excel.stepper.html",
  styleUrls: ["../../shared/share.style.css", "./prodreg.excel.stepper.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class ProdRegExcelStepperComponent implements OnInit, AfterViewInit {
  @Language() lang: string;
  private localeObserve = this.locale.languageCodeChanged.asObservable();

  isDisabled: boolean;  // when there's no parsed product data, we don't go next
  isLinear = true;  // set the stepper to be linear
  numItems: number; // number of items submitted for registration
  errMsg: string;

  columns = [];
  displayedColumns = [];
  highlightRow = [];
  dataSource: ProdRegExcelStepperDataSource;

  constructor(public router: Router,
              private dataAccessService: DataAccessService,
              private snackBar: SnackbarComponent,
              private prodRegExcelService: ProdRegExcelService,
              private dialog: MatDialog,
              public translation: TranslationService,
              public locale: LocaleService) {}

  ngOnInit() {
    this.isDisabled = true;
    this.prodRegExcelService.resetProdRegItems();

    this.dataSource = new ProdRegExcelStepperDataSource(this.prodRegExcelService);
    this.dataSource.prodRegExcelStepperGetAll();
  }

  ngAfterViewInit() {
    this.localeObserve.subscribe(response => {
      if (!_.isNil(response)) {
        this.translation.loadTranslation().then(() => {});
      }
    });
  }

  OnFileDropEvent(event) {
    const oneFile = event.files[0];
    const reader: FileReader = new FileReader();
    this.isDisabled = _.isEmpty(oneFile);

    if (oneFile.fileEntry.isFile) {
      const fileEntry = oneFile.fileEntry as FileSystemFileEntry;

      fileEntry.file((file: File) => {
        reader.onload = (ev: any) => {
          const workbook = xlsx.read(ev.target.result, {type: "binary"});
          const worksheet: WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
          const productDataJson = xlsx.utils.sheet_to_json(worksheet);
          this.prodRegExcelService.updateProdRegItems(productDataJson); // update product data service
          this.UpdateColumnDefinitions(); // update column definitions
        };

        reader.readAsBinaryString(file);
      });
    }
  }

  ClearProductData(event) {
    event.preventDefault();
    this.isDisabled = true;
    this.prodRegExcelService.resetProdRegItems(); // reset product data service
    this.UpdateColumnDefinitions(); // update column definitions
  }

  UpdateColumnDefinitions() {
    this.BuildColumnDefinitions().subscribe(colDef => {
      this.columns = colDef;
      this.displayedColumns = this.GetDisplayColumns(colDef);
    });
  }

  BuildColumnDefinitions() {
    const rejectKeys = ["productLogo"];

    return Observable.create(observer => {
      this.prodRegExcelService.currentProdRegItems
        .subscribe(productItems => {
          const columnData = [];
          const colKeys = _.keys(_.sample(productItems));

          // filter out keys we don't want to display as column headers
          const colKeysFiltered = _.reject(colKeys, key => _.indexOf(rejectKeys, key) !== -1);

          // build column definitions for the table
          _.forEach(colKeysFiltered, key => {
            columnData.push({ columnDef: key, header: key, cell: (item: any) => `${item[key]}`});
          });

          observer.next(columnData);
          observer.complete();
        });
    });
  }

  GetDisplayColumns(colDef) {
    return colDef.map(col => col.columnDef);
  }

  BuildDialogConfig(productData): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig();
    config.width = "1080px";
    config.height = "640px";
    config.data = productData;
    return config;
  }

  AddImageToProductData(productData) {
    const dialogConfig = this.BuildDialogConfig(productData);
    const dialogRef = this.dialog.open(ProdRegLogoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (!_.isUndefined(result)) {
        this.snackBar.success(result);
      }
    });
  }

  SubmitProductRegistration(event) {
    event.preventDefault();

    this.prodRegExcelService.currentProdRegItems
      .subscribe(productData => {
        this.numItems = _.size(productData);

        _.forEach(productData, item => {
          this.dataAccessService.companyProductCreate(item)
            .subscribe(response => {
              if (response.code === 200) {
                this.numItems--;
              }
              if (this.numItems < 1) {
                const msg = this.translation.translate("productStepper.snackSuccess");
                this.snackBar.success(msg);
              }
            }, error => {
              this.errMsg = error.message;
              this.snackBar.error(error.message);
            });

        });
      });
  }
}
