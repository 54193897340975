/**
 * File: snackbar.component
 * Created by Ricky Leung on 2018-05-19
 */
import {Component} from "@angular/core";
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from "@angular/material";
import {SnackbarSuccessComponent} from "../snackbar-success/snackbar-success.component";
import {SnackbarErrorComponent} from "../snackbar-error/snackbar-error.component";
import {SnackbarAlertComponent} from "../snackbar-alert/snackbar-alert.component";

@Component({
  selector: "onelab-snackbar",
  templateUrl: "./snackbar.html",
  styleUrls: ["./snackbar.css", "../share.style.css"]
})
export class SnackbarComponent {
  private duration = 5000;

  constructor(public snackBar: MatSnackBar) {
  }

  success(message: string) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.data = message;
    config.duration = this.duration;
    this.snackBar.openFromComponent(SnackbarSuccessComponent, config);
  }

  errorDuration(message: string, dur:number) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.data = message;
    config.duration = dur;
    this.snackBar.openFromComponent(SnackbarErrorComponent, config);
  }
  
  error(message: string) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.data = message;
    config.duration = this.duration;
    this.snackBar.openFromComponent(SnackbarErrorComponent, config);
  }
  

  alert(message: string) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.data = message;
    config.duration = this.duration;
    this.snackBar.openFromComponent(SnackbarAlertComponent, config);
  }
  dismiss() {
    this.snackBar.dismiss();
  }
  setDuration(duration:number) {
    this.duration = duration;

  }
}
