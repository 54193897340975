/**
 * File: onelab-input-general
 * Created by Ricky Leung on 2017-10-28
 */
import {Component, Input, OnDestroy, HostBinding, ElementRef, Renderer2} from "@angular/core";
import {DInput} from "../../models/dinput";
import {MatFormFieldControl} from "@angular/material";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {FocusMonitor} from "@angular/cdk/a11y";
// import {FocusMonitor} from "@angular/cdk/a11y";
// import {FocusMonitor} from "@angular/cdk/a11y";

/** Custom 'MatFormFieldControl' for password input */
@Component({
  selector: "onelab-input-general",
  templateUrl: "./onelab-input-general.html",
  styleUrls: ["../share.style.css", "./onelab-input-general.css"],
  providers: [{provide: MatFormFieldControl, useExisting: OnelabInputGeneralComponent}]
})
export class OnelabInputGeneralComponent implements MatFormFieldControl<DInput>, OnDestroy {
  private _id: string;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _type: string;
  private _icon: string;
  private _shouldLabelFloat: boolean;

  input: FormGroup;  // this is for the password input
  // hide = true;    // this is to show/hide the password

  stateChanges = new Subject<void>();
  ngControl = null;
  focused = false;
  errorState = false;
  // shouldLabelFloat = false;

  @HostBinding("attr.aria-describedby")
  describedBy = "";

  @HostBinding("class.floating")
  get shouldPlaceholderFloat() {
    return this.focused || !this.empty;
  }

  // FocusMonitor returns an observable that emits when the focus state of the element changes.
  // When element is blurred, null will be emitted
  // origin = 'touch' | 'mouse' | 'keyboard' | 'program' | 'null'
  constructor(fb: FormBuilder, private fm: FocusMonitor, private elRef: ElementRef, renderer: Renderer2) {
    this.input = fb.group({
      "data": ""
    });

    // fm.monitor(elRef.nativeElement, renderer, true).subscribe((origin) => {
    //   this.focused = !!origin;
    //   this.stateChanges.next();
    // });

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  get empty() {
    const n = this.input.value;
    return !n.data;
  }

  @Input()
  get value(): DInput | null {
    const n = this.input.value;
    if (n.data.length > 0) {
      return new DInput(n.data);
    }
    return null;
  }
  set value(inputValue: DInput | null) {
    console.log(inputValue);  // todo: debugging...
    inputValue = inputValue || new DInput("");
    this.input.setValue({data: inputValue.data});
    this.stateChanges.next();
  }

  @Input()
  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  // custom input to determine the input type
  @Input()
  get type() {
    return this._type;
  }
  set type(tp) {
    this._type = tp;
    this.stateChanges.next();
  }

  // custom input to determine what Material Icon to use
  @Input()
  get matIcon() {
    return this._icon;
  }
  set matIcon(icn) {
    this._icon = icn;
    this.stateChanges.next();
  }

  @Input()
  get shouldLabelFloat() {
    return this._shouldLabelFloat;
  }
  set shouldLabelFloat(flt) {
    this._shouldLabelFloat = flt;
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(" ");
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== "input") {
      this.elRef.nativeElement.querySelector("input").focus();
    }
  }
}
