/**
 * File: prodreg.excel.stepper.datasource
 * Created by Ricky Leung on 2018-08-18
 */
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, of, Observable} from "rxjs";
import {ProdRegExcelService} from "../prodreg.services/prodreg.excel.service";
import {catchError, finalize} from "rxjs/operators";

export class ProdRegExcelStepperDataSource implements DataSource<any> {
  private prodRegExcelStepperDataSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private prodRegExcelService: ProdRegExcelService) {}

  prodRegExcelStepperGetAll() {
    this.loadingSubject.next(true);
    this.prodRegExcelService.currentProdRegItems.pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(data => {
      this.prodRegExcelStepperDataSubject.next(data);
    });
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.prodRegExcelStepperDataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.prodRegExcelStepperDataSubject.complete();
  }
}
