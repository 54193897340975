/**
 * Project: authenticity
 * File: onelab-render-sidenav.component
 * Created by PCAPPS52 Ricky Leung on 1/16/2018 11:27 AM.
 * Description/Comments:
 */
import {AfterViewInit, Component, OnChanges, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {ONELAB_RENDER_SIDENAV_DATA} from "../../shared.data/onelab-render-sidenav.data";
import {ONELAB_RENDER_SIDENAV_ROLES_DATA} from "../../shared.data/onelab-render-sidenav-roles.data";
import * as _ from "lodash";
import {Language, LocaleService, TranslationService} from "angular-l10n";

@Component({
  selector: "onelab-render-sidenav",
  templateUrl: "./onelab-render-sidenav.html",
  styleUrls: ["../share.style.css", "./onelab-render-sidenav.css"]
})
export class OnelabRenderSideNavComponent implements OnInit, AfterViewInit {
  public spacer = 5; // gives nested sidenav menu items a spacer before rendering the rest of component
  public noSpacer = 0.01;
  private masterSideNavElements = ONELAB_RENDER_SIDENAV_DATA; // load the master list of side nav elements
  private masterSideNavRoleElements = ONELAB_RENDER_SIDENAV_ROLES_DATA; // load master list of sidenav elements
  public sideNavElements = []; // final list of side nave elements to be rendered
  private localeObserve = this.locale.languageCodeChanged.asObservable();

  constructor(public router: Router,
              public translation: TranslationService,
              public locale: LocaleService) {}

  ngOnInit() {
    // this.BuildSideNavElements(JSON.parse(localStorage.getItem("permissionData")));
    this.BuildSideNavRoleElements(JSON.parse(localStorage.getItem("roleData")));
  }

  ngAfterViewInit() {
    // whenever language code changes, load translation and rebuild sidenav menu
    this.localeObserve.subscribe(response => {
      if (!_.isNil(response)) {
        this.translation.loadTranslation().then(() => {
          // this.BuildSideNavElements(JSON.parse(localStorage.getItem("permissionData")));
          this.BuildSideNavRoleElements(JSON.parse(localStorage.getItem("roleData")));
        });
      }
    });
  }

  // just builds a single new side nav element based on supplied values
  SideNavElement(groupEnable: boolean, groupToggle: boolean, groupMatIcon: string, groupNavLabel: string, links: any) {
    return {
      groupEnable: groupEnable,
      groupToggle: groupToggle,
      groupMatIcon: groupMatIcon,
      groupNavLabel: groupNavLabel,
      links: links
    };
  }

  // iterate through an array of nav links and check if user has permission to access it
  // SideNavLinks(links: any, permissions: any) {
  //   const returnLinks = [];
  //   const permissionKeys = Object.keys(permissions.permissions);
  //   permissionKeys.push("NONE");  // [Ricky]: this is a "special" permission key that isn't part of the permission object
  //
  //   links.forEach(oneLink => {
  //     oneLink.navLabel = this.translation.translate(oneLink.navLabelKey); // use the label key to get translation value
  //     // if oneLink.permissionKey is in the user's list of permission keys...
  //     if (_.indexOf(permissionKeys, oneLink.permissionKey) !== -1) {
  //       // from user's permission data, get the permission actions for the specified key...
  //       const permissionActions = permissions.permissions[oneLink.permissionKey];
  //
  //       // if user's permission action is contained/intersects with the link's required permission action
  //       //  OR permission key is "none" so no permissions required... update links to return
  //       if ((!_.isEmpty(_.intersection(oneLink.permissionActions, permissionActions))) || (oneLink.permissionKey === "NONE")) {
  //         returnLinks.push(oneLink);
  //       }
  //     }
  //   });
  //
  //   return returnLinks;
  // }

  // build the complete side nav object for rendering
  // BuildSideNavElements(permissionData: any) {
  //   this.sideNavElements = [];  // reset sidenav elements since we're rebuilding them
  //   this.masterSideNavElements = ONELAB_RENDER_SIDENAV_DATA;  // reload sidenav data.. in theory shouldn't be necessary
  //
  //   // loop through master list of side nav elements
  //   this.masterSideNavElements.forEach(el => {
  //     // loop through each array of links in one side nav element and check permission keys
  //     const links = this.SideNavLinks(el.links, permissionData);
  //
  //     // if the links array returned is not empty, build nav element and push to sideNavElements object
  //     if (!_.isEmpty(links)) {
  //       const groupNavLabel = this.translation.translate(el.groupNavLabelKey); // use the label key to get translation value
  //       const navElem = this.SideNavElement(el.groupEnable, el.groupToggle, el.groupMatIcon, groupNavLabel, links);
  //       this.sideNavElements.push(navElem);
  //     }
  //   });
  // }

  SideNavRoleLinks(links: any, roleData: any) {
    const returnLinks = [];

    links.forEach(oneLink => {
      // oneLink.navLabel = this.translation.translate(oneLink.navLabelKey); // use label key to get translation value
      oneLink.navLabel = !_.isEmpty(oneLink.navLabelKey) ? this.translation.translate(oneLink.navLabelKey) : oneLink.navLabel;


      if (!_.isEmpty(_.intersection(roleData, oneLink.roles))) {
        returnLinks.push(oneLink);
      }
    });
    return returnLinks;
  }

  BuildSideNavRoleElements(roleData: any) {
    this.sideNavElements = []; // reset sidenav elements since we're rebuilding them
    this.masterSideNavRoleElements = ONELAB_RENDER_SIDENAV_ROLES_DATA; // reload sidenav data

    // loop through master list of sidenav elements
    this.masterSideNavRoleElements.forEach(el => {
      const links = this.SideNavRoleLinks(el.links, roleData);

      if (!_.isEmpty(links)) {
        const groupNavLabel = !_.isEmpty(el.groupNavLabelKey) ? this.translation.translate(el.groupNavLabelKey) : el.groupNavLabel;
        // const groupNavLabel = this.translation.translate(el.groupNavLabelKey);
        const navElem = this.SideNavElement(el.groupEnable, el.groupToggle, el.groupMatIcon, groupNavLabel, links);
        this.sideNavElements.push(navElem);
      }
    });
  }

}
