/**
 * File: role.canActivateGuard
 * Created by Ricky Leung on 2019-06-01
 */
import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {DataAccessService} from "../shared/dataAccess.service";
import {Observable} from "rxjs";
import {SnackbarComponent} from "../shared/snackbar/snackbar.component";

@Injectable()
export class CanActivateViaRoleGuard implements CanActivate {

  // constructor(public router: Router, private dataAccessService: DataAccessService,
  //             private snackBar: SnackbarComponent) {}

  constructor(public router: Router, private dataAccessService: DataAccessService) {}

  canActivate() {
    // every time someone login, destroy any existing permission data
    // localStorage.removeItem("permissionData");
    localStorage.removeItem("roleData");

    // create a new observable so that we return the get permission response
    return Observable.create(observer => {
      if (localStorage.getItem("roleData") === null) {
        this.dataAccessService.getProfile()
          .subscribe(response => {
            const roleData = response.message.roles;
            localStorage.setItem("roleData", JSON.stringify(roleData));
            observer.next(localStorage.getItem("roleData") !== null);
            observer.complete();
          }, error => {

          });
      }
    });

  }
}
