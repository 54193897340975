/**
 * File: orders.new.table.service
 * Created by Ricky Leung on 2018-10-06
 */
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/index";
import * as _ from "lodash";
import {IImage} from "../../models/iimage";
import {map, reduce} from "rxjs/internal/operators";

@Injectable()
export class OrdersNewTableService {
  private _purchaseOrderData: any = [];
  private purchaseOrderDataSource = new BehaviorSubject<any>(this._purchaseOrderData);
  currentProductOrderData = this.purchaseOrderDataSource.asObservable();
  // private _totalQuantity = 0;
  // private totalQuantityDataSource = new BehaviorSubject<any>(this._totalQuantity);
  // totalQuantity = this.totalQuantityDataSource.asObservable();
  // currentTotal: any = 0;  // just update it as a static value instead of using an observable

  constructor() {}

  private updatePOItemQuantity(poItemData: any, quantity: number) {
    poItemData["quantity"] = quantity;
    return poItemData;
  }

  resetPurchaseOrderData() {
    this.purchaseOrderDataSource.next([]);
  }

  // resetTotalQuantity() {
  //   this.totalQuantityDataSource.next(0);
  // }

  updatePurchaseOrderData(poItemData: any, quantity: number) {
    console.log("orders.new.table.service -- updatePurchaseOrderData");
    const currentPO = this.purchaseOrderDataSource.getValue();
    let updatePO = [];
    let updatePOItem: any;
    // let totalQuantity: number;
    quantity = (_.toNumber(quantity) < 0) ? 0 : _.toNumber(quantity);

    // update if the quantity is greater or equal to zero...
    if (_.toNumber(quantity) >= 0) {
      updatePOItem = this.updatePOItemQuantity(poItemData, quantity);
      const itemIndex = _.findIndex(currentPO, {id: updatePOItem.id});

      if ((itemIndex === -1) && (quantity > 0)) {
        updatePO = _.concat(currentPO, updatePOItem);
      } else if (itemIndex !== -1) {
        if (quantity > 0) {
          currentPO.splice(itemIndex, 1, updatePOItem);
        } else if (quantity < 1) {
          currentPO.splice(itemIndex, 1);
        }
        updatePO = currentPO;
      } else {
        updatePO = currentPO;
      }

      this.resetPurchaseOrderData();
      this.purchaseOrderDataSource.next(updatePO);

      // totalQuantity = updatePO.map(item => item["quantity"]).reduce((acc, value) => _.toNumber(acc) + _.toNumber(value), _.toNumber(0));
      // this.currentTotal = totalQuantity;
    }
  }

  // updateTotalQuantity() {
  //   console.log("orders.new.table.service -- updateTotalQuantity");
  //   this.currentProductOrderData.pipe(
  //     map(item => item["quantity"]),
  //     reduce((acc, value) => _.toNumber(acc) + _.toNumber(value), _.toNumber(0))
  //   ).subscribe(total => {
  //     this.totalQuantityDataSource.next(total);
  //   });
  // }

}
