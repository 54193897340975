/**
 * File: twofactorauth.component.ts
 * Created by Ricky Leung on 2017-11-18
 */
import {Component, Input, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {DataAccessService} from "../../shared/dataAccess.service";
import {SnackbarComponent} from "../../shared/snackbar/snackbar.component";
import {Language} from "angular-l10n";

@Component({
  selector: "app-twofactorauth",
  templateUrl: "twofactorauth.html",
  styleUrls: ["../../shared/share.style.css", "twofactorauth.css"],
  providers: [DataAccessService, SnackbarComponent]
})
export class TwoFactorAuthComponent implements OnInit {
  @Input() twoFactorAuthToken: string;
  @Output() secretToken: string;
  @Output() isDisabled: boolean;
  @Language() lang: string;

  twoFactorAuthIsEnabled = false;
  twoFactorAuthDataUrl: string;

  constructor(public router: Router, private dataAccessService: DataAccessService,
              private snackBar: SnackbarComponent) {}

  // -- lifecycle hooks
  ngOnInit() {
    this.isDisabled = true;
    this.Init2FASetup();  // initialize 2FA data and UI
  }

  // -- methods
  // 1. GET /2fa/setup -- need to see if 2FA is currently enabled or disabled
  // 2. POST /2fa/setup -- if 2FA is currently disabled, use this to enable 2FA
  // 3. POST /2fa/verify -- when 2FA is enable, user must verify by enter OTP
  // 4. DELETE /2fa/setup -- if 2FA is currently enabled, use this to disable 2FA, user must enter OTP

  /*
  * Init2FASetup
  *
  * Initialize the 2FA display data by first checking if already exists
  * If it exists display the 2FA data
  * */
  Init2FASetup() {
    this.dataAccessService.get2FASetup()
      .subscribe(
        response => {
          if (response.code === 200) {
            this.Display2FASetup(response);
          } else if (response.code === 404) {
            this.Create2FASetup();
          }
        },
        error => {
          this.snackBar.error(error.message);
        }
      );
  }

  Create2FASetup() {
    this.dataAccessService.create2FASetup()
      .subscribe(
        response => {
          if (response.code === 200) {
            this.Display2FASetup(response);
          }
        },
        error => {
          this.snackBar.error(error.message);
        }
      );
  }

  Display2FASetup(res: any) {
    const body = res.message;
    this.twoFactorAuthDataUrl = body.dataURL;

    // if "secret" length is greater than zero, 2FA is enabled
    if (body.secret.length > 0) {
      this.twoFactorAuthIsEnabled = true;
      this.secretToken = body.secret;
    } else if (body.secret.length === 0) { // else if "secret" length is equal to zero, 2FA is disabled
      this.twoFactorAuthIsEnabled = false;
      this.secretToken = body.tempSecret;
    }
  }

  CheckRequired2FA(otp) {
    // if the otp is not 6 characters long, keep the "submit" button disabled (isDisabled = true)
    this.isDisabled = (otp.length !== 6);
  }

  Verify2FA(event, otp) {
    event.preventDefault();
    const _otp = otp;

    this.dataAccessService.verify2FA(_otp)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.twoFactorAuthIsEnabled = true;
            this.isDisabled = true;
            this.snackBar.success(response.message);
          }
        },
        error => {
          this.snackBar.error(error.message);
        }
      );
  }

  Disable2FA(event, otp) {
    event.preventDefault();
    const _otp = otp;

    this.dataAccessService.disable2FA(_otp)
      .subscribe(
        response => {
          if (response.code === 200) {
            this.twoFactorAuthIsEnabled = false;
            this.isDisabled = true;
            this.snackBar.success(response.message);
            this.Create2FASetup();
          }
        },
        error => {
          this.snackBar.error(error.message);
        }
      );
  }

}
