/**
 * Created by camsongiang on 2017-02-27.
 */
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { DataAccessService } from "../shared/dataAccess.service";

@Component({
  selector: "app-secure",
  templateUrl: "secure.component.html",
  styleUrls: ["../shared/share.style.css", "secure.component.css"],
  providers : [ DataAccessService ]
})
export class SecureComponent {

  // these are the config options for SimpleNotificationComponent
  public options = {

  };

  constructor(private router: Router , private dataAccessService: DataAccessService) {}

}
