/**
 * File: logout.component
 * Created by Ricky Leung on 2017-12-02
 */
import {Component, OnInit} from "@angular/core";
import {DataAccessService} from "../shared/dataAccess.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-logout",
  templateUrl: "logout.html",
  styleUrls: ["logout.css"],
  providers: [DataAccessService]
})
export class LogOutComponent implements OnInit {
  constructor(public router: Router, private dataAccessService: DataAccessService) {}

  ngOnInit() {
    this.SignOut();
  }

  SignOut() {
    // console.log("---------- INSIDE SignOut");
    this.dataAccessService.logout();  // TODO: need to see if I can make this an Observable, maybe a bit overkill...
    this.router.navigateByUrl("/login");
  }
}
