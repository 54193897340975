/**
 * File: loader-intercept.service
 * Created by Ricky Leung on 2019-03-17
 */
import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {tap} from "rxjs/operators";
import {LoaderService} from "./loader/loader.service";

@Injectable()
export class LoaderInterceptService implements HttpInterceptor {
  private debug = false;

  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.consoleLog("++++++++ loader intercept service -- show", this.debug);

    this.loaderService.show();  // show our loader here...

    return next.handle(req).pipe(
      tap(nxt => {

        this.consoleLog("++++++++ loader intercept service -- tap.nxt", this.debug);
        this.consoleLog(nxt, this.debug);

        if (nxt instanceof HttpResponse) {
          // todo: loading complete...
          this.consoleLog("++++++++ loader intercept service -- HttpResponse -- hide", this.debug);

          this.loaderService.hide();  // hide our loader when we get a http response...
        }
      }, err => {
        // todo: loading complete...
        this.consoleLog("++++++++ loader intercept service -- HttpError -- hide", this.debug);

        this.loaderService.hide();  // hide our loader when we get a http error...
      })
    );

  }

  private consoleLog(msg: any, debug: boolean) {
    if (debug) {
      console.log(msg);
    }
  }
}
