/**
 * File: prodreg.getall.datasource
 * Created by Ricky Leung on 2018-08-12
 */
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, of, Observable} from "rxjs";
import {DataAccessService} from "../../shared/dataAccess.service";
import {catchError, finalize} from "rxjs/operators";
import * as _ from "lodash";
import {IImage} from "../../models/iimage";

export class ProdRegGetAllDataSource implements DataSource<any> {
  private prodRegDataSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private dataAccessService: DataAccessService) {}

  prodRegGetAll() {
    this.loadingSubject.next(true);

    this.dataAccessService.companyProductGetAll().pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(response => {
      const productDataItems = response.message;

      _.forEach(productDataItems, (item) => {
        if (!_.isUndefined(item.productLogo)) {
          const productLogo: IImage = item.productLogo;
          item["Image"] = productLogo.value;
        }
      });

      this.prodRegDataSubject.next(productDataItems);
    });
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.prodRegDataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.prodRegDataSubject.complete();
    this.loadingSubject.complete();
  }
}
